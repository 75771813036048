import AddIcon from '@mui/icons-material/Add';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AllCasesIcon from '@mui/icons-material/AllInbox';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DescriptionIcon from '@mui/icons-material/Description';
import HomeIcon from '@mui/icons-material/Home';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ScienceIcon from '@mui/icons-material/Science';
import SearchIcon from '@mui/icons-material/Search';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { Box, Button, Divider, Stack, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUnsavedChanges } from '../../Context/UnsavedChangesContext';
import { useAuth } from '../../utils/auth/AuthService';
import UnsavedChangesDialog from '../UnsavedChangesDialog';
// import { Divider } from '@mui/material';
import FloodIcon from '@mui/icons-material/Flood';

// Define the props type
interface MLSideBarButtonProps {
  navigationPageTitle?: string;
  icon?: React.ReactNode;
  authorizedRoles?: string[];
  title?: string;
}

const MLSideBarButton: React.FC<MLSideBarButtonProps> = ({
  navigationPageTitle,
  icon,
  authorizedRoles,
  title = '',
}) => {
  const location = window.location;
  const navigate = useNavigate();
  const { user } = useAuth();
  const theme = useTheme();
  const { VITE_URL } = import.meta.env;
  const { hasUnsavedChanges, resetUnsavedChanges } = useUnsavedChanges();
  const [unsavedDialogOpen, setUnsavedDialogOpen] = useState<boolean>(false);

  const handleNavigation = () => {
    if (hasUnsavedChanges) {
      setUnsavedDialogOpen(true);
    } else {
      navigate(`/${navigationPageTitle}`);
    }
  };

  const handleUnsavedDialogLeave = () => {
    resetUnsavedChanges();
    setUnsavedDialogOpen(false);
    navigate(`/${navigationPageTitle}`);
  };

  const handleUnsavedDialogCancel = () => {
    setUnsavedDialogOpen(false);
  };

  const isActive =
    location.pathname?.toLowerCase() === `${VITE_URL}${navigationPageTitle?.toLowerCase()}`;
  const isAuthorized = authorizedRoles == null || user?.roleCheck(authorizedRoles);

  return (
    <div>
      {isAuthorized && (
        <Button
          onClick={handleNavigation}
          sx={{
            width: '100%',
            p: 1,
            color: isActive ? theme.palette.primary.main : theme.palette.text.primary,
            whiteSpace: 'nowrap',
            textAlign: 'center',
            textTransform: 'none',
            borderRadius: 0,
          }}
        >
          <Stack alignItems='center'>
            {icon}
            <Typography sx={{ fontSize: '7pt' }}>{title}</Typography>
          </Stack>
        </Button>
      )}
      <UnsavedChangesDialog
        open={unsavedDialogOpen}
        onLeave={handleUnsavedDialogLeave}
        onCancel={handleUnsavedDialogCancel}
      />
    </div>
  );
};

export default function MLSideBar() {
  const theme = useTheme();

  return (
    <Box
      component='div'
      sx={{
        height: '100%',
        width: 70,
        borderRight: `1px solid ${theme.palette.text.primary}4`,
        py: 1,
      }}
    >
      <Stack spacing={2} alignItems='center'>
        <MLSideBarButton navigationPageTitle='home' icon={<HomeIcon />} title='Home' />

        <MLSideBarButton
          navigationPageTitle='dashboard'
          authorizedRoles={['DASHBOARD']}
          icon={<DashboardIcon />}
          title='Dashboard'
        />

 

        <MLSideBarButton
          navigationPageTitle='dailyexamlist'
          authorizedRoles={['DAILY-EXAM-LIST']}
          icon={<PendingActionsIcon />}
          title='Daily Exams'
        />

        <MLSideBarButton
          navigationPageTitle='yourcasesandreports'
          icon={<AssignmentIcon />}
          title='Your Cases'
        />

        <MLSideBarButton
          navigationPageTitle='caseintake'
          authorizedRoles={['CREATE-CASE']}
          icon={<AddIcon />}
          title='New Case'
        />

        <Divider sx={{ borderColor: theme.palette.text.primary, opacity: 0.4, width: '80%' }} />

        <MLSideBarButton navigationPageTitle='casesearch' icon={<SearchIcon />} title='Search' />

        <MLSideBarButton
          navigationPageTitle='reports'
          authorizedRoles={['REPORTS-MENU']}
          icon={<DescriptionIcon />}
          title='Analytics'
        />

        <MLSideBarButton
          navigationPageTitle='caselist'
          authorizedRoles={['ALL-CASES-LIST']}
          icon={<AllCasesIcon />}
          title='All Cases'
        />

        <MLSideBarButton
          navigationPageTitle='resources'
          icon={<HomeRepairServiceIcon />}
          title='Resources'
        />
   

        <Divider sx={{ borderColor: theme.palette.text.primary, opacity: 0.4, width: '80%' }} />

        <MLSideBarButton
          navigationPageTitle='lms'
          authorizedRoles={['eeacd7ee-fd33-46a7-95b3-26a58b20a5a2']}
          icon={<ScienceIcon />}
          title='LMS'
        />

        <MLSideBarButton
          navigationPageTitle='support'
          authorizedRoles={['8f7']}
          icon={<SupportAgentIcon />}
          title='Spt Agent'
        />

        <MLSideBarButton
          navigationPageTitle='sysadmin'
          authorizedRoles={['d1582600-5f86-49dd-bab7-6f7205bfeffd']}
          icon={<AdminPanelSettingsIcon />}
          title='Sys Admin'
        />

        <MLSideBarButton
          navigationPageTitle='dvcs/home'
          authorizedRoles={['b06']}
          icon={<FloodIcon />}
          title='DVCS'
        />
      </Stack>
    </Box>
  );
}
