import IncidentCard from './IncidentCard';

const MassFatalityCases = () => {
  return (
    // <div>
    //   <Typography variant="h4" gutterBottom>
    //     Mass Fatality Cases
    //   </Typography>
    //   <Typography variant="h6" color="textSecondary" gutterBottom>
    //     Active Incident: {incident?.name} ({incident?.number})
    //   </Typography>
    //   <div style={{ height: 400, width: "100%" }}>
    //     <DataGrid rows={fatalityData} columns={columns} pageSize={5} />
    //   </div>
    // </div>
    <IncidentCard />
  );
};

export default MassFatalityCases;
