import { Lock, LockOpen, TextFields } from '@mui/icons-material';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import type { EditorOptions } from '@tiptap/core';
import {
  LinkBubbleMenu,
  MenuButton,
  RichTextEditor,
  RichTextReadOnly,
  TableBubbleMenu,
  insertImages,
  type RichTextEditorRef,
} from 'mui-tiptap';
import { useCallback, useEffect, useRef, useState } from 'react';
import EditorMenuControls from './EditorMenuControls';
import useExtensions from './useExtensions';
import YouTubeIcon from '@mui/icons-material/YouTube';

async function convertToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

interface RichTextTypographyProps {
  cardContent: string;
  setCardContent: (content: string) => void;
  sx?: object;
}

export default function RichTextTypography({
  cardContent,
  setCardContent,
  sx,
}: RichTextTypographyProps) {
  const extensions = useExtensions({ placeholder: 'Add home card content here...' });
  const rteRef = useRef<RichTextEditorRef>(null);
  const [isEditable, setIsEditable] = useState(true);
  const [showMenuBar, setShowMenuBar] = useState(true);
  const [isYoutubeVideoDialogOpen, setIsYoutubeVideoDialogOpen] = useState(false);
  const [youtubeVideoUrl, setYoutubeVideoUrl] = useState('');
  const [isVideoDialogOpen, setIsVideoDialogOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  useEffect(() => {
    const editor = rteRef.current?.editor;
    if (!editor) return;

    const updateHandler = () => {
      setCardContent(editor.getHTML());
    };

    editor.on('update', updateHandler);

    return () => {
      editor.off('update', updateHandler);
    };
  }, [rteRef.current, cardContent]);

  const handleNewImageFiles = useCallback(async (files: File[], insertPosition?: number) => {
    if (!rteRef.current?.editor) {
      return;
    }

    try {
      const base64Images = await Promise.all(
        files.map(async file => {
          const base64 = await convertToBase64(file);
          return { src: base64, alt: file.name };
        })
      );

      insertImages({
        images: base64Images,
        editor: rteRef.current.editor,
      });
    } catch (error) {
      console.error('Image conversion error:', error);
    }
  }, []);

  const handleDrop: NonNullable<EditorOptions['editorProps']['handleDrop']> = useCallback(
    (view, event, _slice, _moved) => {
      if (!(event instanceof DragEvent) || !event.dataTransfer) {
        return false;
      }

      const imageFiles = Array.from(event.dataTransfer.files).filter(file =>
        file.type.startsWith('image/')
      );
      if (imageFiles.length > 0) {
        const insertPosition = view.posAtCoords({
          left: event.clientX,
          top: event.clientY,
        })?.pos;

        handleNewImageFiles(imageFiles, insertPosition);
        event.preventDefault();
        return true;
      }

      return false;
    },
    [handleNewImageFiles]
  );

  const handlePaste: NonNullable<EditorOptions['editorProps']['handlePaste']> = useCallback(
    (_view, event, _slice) => {
      if (!event.clipboardData) {
        return false;
      }

      const pastedImageFiles = Array.from(event.clipboardData.files).filter(file =>
        file.type.startsWith('image/')
      );
      if (pastedImageFiles.length > 0) {
        handleNewImageFiles(pastedImageFiles);
        return true;
      }

      return false;
    },
    [handleNewImageFiles]
  );

  const handleEmbedVideo = useCallback(() => {
    if (!rteRef.current?.editor) return;

    if (videoUrl) {
      rteRef.current.editor
        .chain()
        .focus()
        .insertContent(`<video src="${videoUrl}" controls></video>`)
        .run();
    }

    setVideoUrl('');
    setIsVideoDialogOpen(false);
  }, [rteRef]);

  const handleEmbedYoutubeVideo = useCallback(() => {
    if (!rteRef.current?.editor || !youtubeVideoUrl.trim()) return;

    const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)/;
    const shortYoutubeRegex = /(?:https?:\/\/)?(?:www\.)?youtu\.be\/([a-zA-Z0-9_-]+)/;

    let embedUrl = youtubeVideoUrl;

    if (youtubeRegex.test(youtubeVideoUrl)) {
      const match = youtubeVideoUrl.match(youtubeRegex);
      embedUrl = `https://www.youtube.com/embed/${match?.[1]}`;
    } else if (shortYoutubeRegex.test(youtubeVideoUrl)) {
      const match = youtubeVideoUrl.match(shortYoutubeRegex);
      embedUrl = `https://www.youtube.com/embed/${match?.[1]}`;
    }

    rteRef.current.editor
      .chain()
      .focus()
      .insertContent(
        `
      <div class="iframe-container">
        <iframe src="${embedUrl}" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    `
      )
      .run();

    setYoutubeVideoUrl('');
    setIsYoutubeVideoDialogOpen(false);
  }, [youtubeVideoUrl, rteRef]);

  return (
    <>
      <Box
        component='div'
        sx={{
          '& .ProseMirror': {
            '& h1, & h2, & h3, & h4, & h5, & h6': { scrollMarginTop: showMenuBar ? 50 : 0 },
          },
          ...sx,
        }}
      >
        <RichTextEditor
          ref={rteRef}
          extensions={extensions}
          content={cardContent}
          editable={isEditable}
          editorProps={{
            handleDrop: handleDrop,
            handlePaste: handlePaste,
          }}
          renderControls={() => <EditorMenuControls />}
          RichTextFieldProps={{
            variant: 'outlined',
            MenuBarProps: { hide: !showMenuBar },
            footer: (
              <Stack
                direction='row'
                spacing={2}
                sx={{
                  borderTopStyle: 'solid',
                  borderTopWidth: 1,
                  borderTopColor: theme => theme.palette.divider,
                  py: 1,
                  px: 1.5,
                }}
              >
                <MenuButton
                  value='formatting'
                  tooltipLabel={showMenuBar ? 'Hide formatting' : 'Show formatting'}
                  size='small'
                  onClick={() => setShowMenuBar(currentState => !currentState)}
                  selected={showMenuBar}
                  IconComponent={TextFields}
                />
                <MenuButton
                  value='formatting'
                  tooltipLabel={isEditable ? 'Prevent edits (use read-only mode)' : 'Allow edits'}
                  size='small'
                  onClick={() => setIsEditable(currentState => !currentState)}
                  selected={!isEditable}
                  IconComponent={isEditable ? Lock : LockOpen}
                />
                <MenuButton
                  value='embed-video'
                  tooltipLabel='Embed Video'
                  size='small'
                  onClick={() => setIsVideoDialogOpen(true)}
                  IconComponent={() => <VideoLibraryIcon />}
                />
                <MenuButton
                  value='embed-youtube-video'
                  tooltipLabel='Embed Youtube Video'
                  size='small'
                  onClick={() => setIsYoutubeVideoDialogOpen(true)}
                  IconComponent={() => <YouTubeIcon />}
                />
              </Stack>
            ),
          }}
        >
          {() => (
            <>
              <LinkBubbleMenu />
              <TableBubbleMenu />
            </>
          )}
        </RichTextEditor>
      </Box>

      <Typography variant='h5' sx={{ my: 5 }}>
        Preview:
      </Typography>

      <RichTextReadOnly content={cardContent} extensions={extensions} />

      <Dialog open={isYoutubeVideoDialogOpen} onClose={() => setIsYoutubeVideoDialogOpen(false)}>
        <DialogTitle>Embed Youtube Video</DialogTitle>
        <Divider sx={{ opacity: 0.8 }} />
        <DialogContent>
          <Stack spacing={2}>
            <Typography>Please enter a YouTube video URL:</Typography>
            <TextField
              autoFocus
              margin='dense'
              id='video-url'
              label='Video URL'
              type='url'
              fullWidth
              variant='outlined'
              value={youtubeVideoUrl}
              onChange={e => setYoutubeVideoUrl(e.target.value)}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color='error' onClick={() => setIsYoutubeVideoDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleEmbedYoutubeVideo} variant='contained' color='primary'>
            Embed
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isVideoDialogOpen} onClose={() => setIsVideoDialogOpen(false)}>
        <DialogTitle>Embed Video</DialogTitle>
        <Divider sx={{ opacity: 0.8 }} />
        <DialogContent>
          <Stack spacing={2}>
            <Typography>Please enter a URL:</Typography>
            <TextField
              autoFocus
              margin='dense'
              id='video-url'
              label='Video URL'
              type='url'
              fullWidth
              variant='outlined'
              value={videoUrl}
              onChange={e => setVideoUrl(e.target.value)}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color='error' onClick={() => setIsVideoDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleEmbedVideo} variant='contained' color='primary'>
            Embed
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
