import { Delete, Download, ZoomIn, ZoomOut } from '@mui/icons-material';
import BiotechIcon from '@mui/icons-material/Biotech';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import HomeIcon from '@mui/icons-material/Home';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Gallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { ResponseStatus } from '../../types/MedexResponse.type';
import { useAuth } from '../auth/AuthService';
import { GuidEmptySeq } from '../constants/GuidEmptySeq';

const { VITE_API_URL, VITE_FASTAPI_URL } = import.meta.env;

const CustomGallery = ({ images, selectedImageIndex, onImageVoided }) => {
  const auth = useAuth();
  const appTheme = useTheme();
  const isMobile = useMediaQuery(appTheme.breakpoints.down('sm'));
  const isLaptop = useMediaQuery(appTheme.breakpoints.up('md'));
  const [dragging, setDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [galleryImages, setGalleryImages] = useState(images);
  const [selectedIndex, setSelectedIndex] = useState(selectedImageIndex);
  const [scrollAmount, setScrollAmount] = useState(0);
  const [analyzeImageModalOpen, setAnalyzeImageModalOpen] = useState(false);
  const [analyzeImageModalContent, setAnalyzeImageModalContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const [currentImageToVoid, setCurrentImageToVoid] = useState(null);
  const [voidConfirmOpen, setVoidConfirmOpen] = useState(false);
  const [isVoiding, setIsVoiding] = useState(false);

  const renderCustomControls = () => {
    const currentImage = galleryImages[selectedIndex] || null;
    const uploadedByUserSeq = currentImage?.uploadedByUserSeq || GuidEmptySeq;

    const authorizedToVoid =
      uploadedByUserSeq?.toLowerCase() === auth.user?.userSeq?.toLowerCase() ||
      auth.user.roleCheck(['f45']); // role code for CASE-SUPER-ADMIN

    const iconStyle = { color: 'white' }; // since background is dark, used white color for icons

    return (
      <Stack direction='row' justifyContent='center'>
        <Tooltip title='Download' arrow>
          <IconButton onClick={handleDownloadClick}>
            <Download color='primary' />
          </IconButton>
        </Tooltip>
        <IconButton onClick={handleZoomInClick}>
          <ZoomIn style={iconStyle} />
        </IconButton>
        <IconButton onClick={handleZoomOutClick}>
          <ZoomOut style={iconStyle} />
        </IconButton>
        <IconButton onClick={handleRotateLeftClick}>
          <RotateLeftIcon style={iconStyle} />
        </IconButton>
        <IconButton onClick={handleRotateRightClick}>
          <RotateRightIcon style={iconStyle} />
        </IconButton>
        <Tooltip title='Reset View' arrow>
          <IconButton onClick={handleHomeClick}>
            <HomeIcon style={iconStyle} />
          </IconButton>
        </Tooltip>

        <Tooltip title='Void' arrow>
          <IconButton
            sx={{ ml: 2 }}
            color={authorizedToVoid ? 'error' : 'default'}
            disabled={!authorizedToVoid}
            onClick={() => handleVoidClick(currentImage)}
          >
            <Delete />
          </IconButton>
        </Tooltip>

        {auth?.user?.roleCheck(['d70']) && (
          <Tooltip title='Analyze Image' arrow>
            <IconButton sx={{ ml: 4 }} onClick={handleAnalyzeImageClick}>
              <AutoFixHighIcon style={iconStyle} />
            </IconButton>
          </Tooltip>
        )}

        {/* "Razzle Dazzle" the Dialog for X-ray analysis results */}
        <Dialog
  open={analyzeImageModalOpen}
  onClose={handleCloseDialog}
  aria-labelledby="analyze-image-dialog-title"
  sx={{
    zIndex: (theme) => theme.zIndex.modal + 1, // Ensure it's above other modals
  }}
>
  {loading ? (
    <>
      <DialogTitle id="analyze-image-dialog-title">Analyzing Image... ✨</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 2,
          }}
        >
          <CircularProgress size={50} />
          <Typography variant="body1" sx={{ mt: 2 }}>
            Please wait. Processing the image
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            <strong>This feature is in development</strong>, a preview is being generated
          </Typography>
        </Box>
      </DialogContent>
    </>
  ) : (
    <>
      <DialogTitle id="analyze-image-dialog-title">Analysis Results</DialogTitle>
      <DialogContent>
        <DialogContentText>Under Development...</DialogContentText>
        <DialogContentText dangerouslySetInnerHTML={{ __html: analyzeImageModalContent }} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleCloseDialog}>
          Close
        </Button>
      </DialogActions>
    </>
  )}
</Dialog>

      </Stack>
    );
  };
  const handleMouseDown = event => {
    event.preventDefault();
    setDragging(true);
    setDragStart({ x: event.clientX, y: event.clientY });
  };

  const handleMouseMove = event => {
    if (dragging) {
      const offsetX = event.clientX - dragStart.x;
      const offsetY = event.clientY - dragStart.y;
      setDragOffset({ x: offsetX, y: offsetY });
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleMouseLeave = () => {
    setDragging(false);
  };

  const handleTouchStart = event => {
    event.preventDefault();
    const touch = event.touches[0];
    setDragging(true);
    setDragStart({ x: touch.clientX, y: touch.clientY });
  };

  const handleTouchMove = event => {
    event.preventDefault();
    if (!dragging) return;
    const touch = event.touches[0];
    const offsetX = touch.clientX - dragStart.x;
    const offsetY = touch.clientY - dragStart.y;
    setDragOffset({ x: offsetX, y: offsetY });
  };

  const handleTouchEnd = () => {
    setDragging(false);
  };

  const handleDoubleClick = () => {
    setGalleryImages(prevImages => {
      const newImages = [...prevImages];
      newImages[selectedIndex].zoomLevel = (newImages[selectedIndex].zoomLevel || 1) === 1 ? 2 : 1;
      return newImages;
    });
  };

  const handleZoomInClick = () => {
    setGalleryImages(prevImages => {
      const newImages = [...prevImages];
      newImages[selectedIndex].zoomLevel = Math.max(
        1,
        (newImages[selectedIndex].zoomLevel || 1) + 0.1
      );
      return newImages;
    });
  };

  const handleZoomOutClick = () => {
    setGalleryImages(prevImages => {
      const newImages = [...prevImages];
      newImages[selectedIndex].zoomLevel = Math.max(
        0.1,
        (newImages[selectedIndex].zoomLevel || 1) - 0.1
      );
      return newImages;
    });
  };

  const handleHomeClick = () => {
    setGalleryImages(prevImages => {
      const newImages = [...prevImages];
      newImages[selectedIndex].zoomLevel = 1;
      newImages[selectedIndex].rotationAngle = 0;
      return newImages;
    });
    setDragOffset({ x: 0, y: 0 });
  };

  const handleDownloadClick = () => {
    const anchor = document.createElement('a');
    anchor.href = galleryImages[selectedIndex].midsize;
    anchor.download = galleryImages[selectedIndex].originalFileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const handleRotateLeftClick = () => {
    const currentImage = galleryImages[selectedIndex];

    if (currentImage) {
      currentImage.rotationAngle = (currentImage.rotationAngle || 0) - 90;
      setGalleryImages([...galleryImages]);
    }
  };

  const handleRotateRightClick = () => {
    const currentImage = galleryImages[selectedIndex];

    if (currentImage) {
      currentImage.rotationAngle = (currentImage.rotationAngle || 0) + 90;
      setGalleryImages([...galleryImages]);
    }
  };

  const handleAnalyzeImageClick = async () => {
    const currentImage = galleryImages[selectedIndex];
    if (currentImage) {
      try {
        setLoading(true);
        setAnalyzeImageModalOpen(true);
        setProgress(0);

        const progressInterval = setInterval(() => {
          setProgress(oldProgress => {
            if (oldProgress >= 100) {
              clearInterval(progressInterval);
            }
            const diff = Math.random() * 10;
            return Math.min(oldProgress + diff, 100);
          });
        }, 200);

        const response = await fetch(VITE_FASTAPI_URL + 'resources/tools/analyze-image', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${auth.user.accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            file_name: currentImage.originalFileName,
            base64_data: currentImage.midsize,
          }),
        });

        const data = await response.json();

        // delay for 6 seconds --> makes the loading/fetching look more legit -will
        await new Promise(resolve => setTimeout(resolve, 6000));

        if (response.ok) {
          setAnalyzeImageModalContent(`
            <strong>MDXAI:</strong> ${data.data.details}
          `);
        } else {
          setAnalyzeImageModalContent('Error: Analysis feature is still under development.');
        }
      } catch (error) {
        console.error('error at image analysis:', error);
        setAnalyzeImageModalContent('Error: Unable to process the request.');
      } finally {
        clearInterval(progress);
        setLoading(false);
      }
    }
  };

  const handleCloseDialog = () => setAnalyzeImageModalOpen(false);

  const handleVoidClick = image => {
    if (image) {
      setCurrentImageToVoid(image);
      setVoidConfirmOpen(true);
    }
  };

  const handleVoidConfirm = async () => {
    if (currentImageToVoid) {
      setIsVoiding(true);
      try {
        const response = await fetch(VITE_API_URL + 'casephotos/void', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${auth.user.accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            photoSeq: currentImageToVoid.photoSeq,
          }),
        });

        const data = await response.json();

        if (data.status === ResponseStatus.SUCCESS) {
          if (onImageVoided && typeof onImageVoided === 'function') {
            onImageVoided(currentImageToVoid.photoSeq, true, 'Image successfully voided');
          }
        } else {
          if (onImageVoided && typeof onImageVoided === 'function') {
            onImageVoided(
              currentImageToVoid.photoSeq,
              false,
              'Failed to void image: ' + data.message
            );
          }
        }
      } catch (error) {
        console.error('Error voiding image:', error);
        if (onImageVoided && typeof onImageVoided === 'function') {
          onImageVoided(
            currentImageToVoid.photoSeq,
            false,
            'Error voiding image: ' + error.message
          );
        }
      } finally {
        setIsVoiding(false);
        setVoidConfirmOpen(false);
        setCurrentImageToVoid(null);
      }
    }
  };

  const handleVoidCancel = () => {
    setVoidConfirmOpen(false);
    setCurrentImageToVoid(null);
  };

  const handleOnChange = index => {
    setSelectedIndex(index);
  };

  const handleScroll = event => {
    event.preventDefault();
    setScrollAmount(prev => prev + event.deltaY);
  };

  useEffect(() => {
    // Check if the accumulated scroll amount crosses the threshold
    if (Math.abs(scrollAmount) > 100) {
      // Adjust this value for sensitivity
      if (scrollAmount > 0) {
        setSelectedIndex(prevIndex => (prevIndex + 1) % galleryImages.length);
      } else {
        setSelectedIndex(
          prevIndex => (prevIndex - 1 + galleryImages.length) % galleryImages.length
        );
      }
      // Reset scroll amount after updating index
      setScrollAmount(0);
    }
  }, [scrollAmount, galleryImages.length]);

  useEffect(() => {
    window.addEventListener('wheel', handleScroll, { passive: false });
    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, []);

  return (
    <>
      {galleryImages.length > 0 ? (
        <div style={{ width: '100%', maxWidth: '100%', height: '100%', maxHeight: '100%' }}>
          <Gallery
            items={galleryImages}
            startIndex={selectedIndex}
            showIndex={true}
            renderCustomControls={renderCustomControls}
            lazyLoad={true}
            useTranslate3D={true}
            onSlide={handleOnChange}
            renderItem={item => (
              <div
                style={{
                  paddingTop: isMobile ? '100%' : isLaptop ? '45%' : '50%',
                  position: 'relative',
                  overflow: 'hidden',
                  cursor: dragging ? 'grabbing' : 'grab',
                }}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onDoubleClick={handleDoubleClick}
                onMouseLeave={handleMouseLeave}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
              >
                <img
                  key={item.photoSeq}
                  src={item.midsize}
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    transform: `translate(${dragOffset.x}px, ${dragOffset.y}px) rotate(${
                      item.rotationAngle || 0
                    }deg) scale(${item.zoomLevel || 1})`,
                    objectFit: 'contain',
                    width: '100%',
                    height: '100%',
                  }}
                  loading='lazy'
                />
                {/* <div
                    style={{
                      position: 'absolute',
                      bottom: '30px',
                      left: '110px',
                      color: 'white',
                      padding: '5px',
                    }}
                  >
                    {item.originalFileName}
                  </div> */}
                <div
                  style={{
                    position: 'absolute',
                    bottom: '10px',
                    left: '110px',
                    color: 'white',
                    padding: '5px',
                  }}
                >
                  {item.photoLabel}
                </div>
              </div>
            )}
          />
        </div>
      ) : (
        <div>No images to display.</div>
      )}

      {/* Void Confirmation Dialog */}
      <Dialog
        open={voidConfirmOpen}
        onClose={handleVoidCancel}
        aria-labelledby='void-confirm-dialog-title'
        aria-describedby='void-confirm-dialog-description'
      >
        <DialogTitle id='void-confirm-dialog-title'>
          {currentImageToVoid ? `Void "${currentImageToVoid.photoLabel}"?` : 'Void Image'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='void-confirm-dialog-description' sx={{ fontWeight: 'bold' }}>
            This cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleVoidCancel} color='primary' autoFocus disabled={isVoiding}>
            Cancel
          </Button>
          <Button onClick={handleVoidConfirm} color='error' disabled={isVoiding}>
            {isVoiding ? <CircularProgress size={24} /> : 'Void'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomGallery;
