import FileUploadIcon from '@mui/icons-material/FileUpload';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridToolbarQuickFilter } from '@mui/x-data-grid-premium';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../../utils/auth/AuthService';
import { dateColumnType } from '../../../../utils/components/CrudDataGrid/DateColumn';
import ReportDataGrid from '../../../../utils/components/ReportDataGrid/ReportDataGrid';

export default function ELabPMTestResultsView() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [listresults, setlistresults] = useState([]);
  const { VITE_API_URL } = import.meta.env;
  const formik = useFormikContext();

  const UploadToMedexLabCaseButton = ({ onUpload }) => {
    const [open, setOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogTitle, setDialogTitle] = useState('');

    const handleClose = () => {
      setOpen(false);
    };

    const handleBulkUploadClick = async () => {
      await bulkUploadResults(listresults);
    };

    const bulkUploadResults = async rows => {
      try {
        let formData = new FormData();
        formData.append('rows', JSON.stringify(rows));
        formData.append('caseID', formik.values?.casesummary?.mdxlabcaseid);
        formData.append('userSeq', user.userSeq);

        const response = await fetch(`${VITE_API_URL}uploadAllElabResultsToMedexLabCase`, {
          method: 'PUT',
          headers: {
            Authorization: 'Bearer ' + user.token,
          },
          body: formData,
        });

        if (response.status === 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (response.status >= 400) {
          const errorText = await response.text();
          throw new Error(errorText || 'An error occurred during bulk upload');
        }

        const data = await response.text();
        console.log('Bulk upload response:', data);

        setDialogTitle('Success');
        setDialogMessage('All results were successfully imported into this case!');
        setOpen(true);
      } catch (error) {
        console.log('Error in bulk upload:', error);
        setDialogTitle('Error');
        setDialogMessage(`There was an error uploading: ${error.message}`);
        setOpen(true);
      }
    };

    return (
      <>
        <Tooltip title='Upload To MedexLab Case'>
          <IconButton onClick={handleBulkUploadClick} size='small' color='primary'>
            <FileUploadIcon />
            <Typography> Upload To Case </Typography>
          </IconButton>
        </Tooltip>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{dialogMessage}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color='primary' autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  const columns = [
    // {
    //   field: 'id',
    //   headerName: 'ID',
    //   flex: 1,
    //   editable: false,
    //   align: 'center',
    //   headerAlign: 'center',
    //   headerClassName: 'header-theme',
    // },
    // {
    //   field: 'samplenumber',
    //   headerName: 'Sample Number',
    //   flex: 1,
    //   editable: false,
    //   align: 'center',
    //   headerAlign: 'center',
    //   headerClassName: 'header-theme',
    // },
    // Not sure if its assumed that caseid in medexlab is going to be the same
    {
      field: 'caseid',
      headerName: 'MedexLab CaseID',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'specimen',
      headerName: 'Specimen',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },

    {
      field: 'analyte',
      headerName: 'Analyte',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'testmethod',
      headerName: 'Test Method',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    // {
    //   field: 'quantitativelimit',
    //   headerName: 'Quantitative Limit',
    //   flex: 1,
    //   minWidth: 150,
    //   editable: false,
    //   align: 'center',
    //   headerAlign: 'center',
    //   headerClassName: 'header-theme',
    // },
    {
      field: 'unitofmeasurement',
      headerName: 'Units',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    // {
    //   field: 'quantitativeresult',
    //   headerName: 'RESULT',
    //   flex: 1,
    //   minWidth: 150,
    //   editable: false,
    //   align: 'center',
    //   headerAlign: 'center',
    //   headerClassName: 'header-theme',
    // },
    {
      field: 'interpretation',
      headerName: 'INTERPRETATION',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'testdate',
      headerName: 'Test Date',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      ...dateColumnType,
      valueGetter: value => {
        if (!value) return undefined;
        if (value === 'Invalid Date') {
          return 'undefined';
        }
        return new Date(value);
      },
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'labreferencenumber',
      headerName: 'Lab Reference Number',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'validationdate',
      headerName: 'Validation Date',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      ...dateColumnType,
      valueGetter: value => {
        if (!value) return undefined;
        if (value === 'Invalid Date') {
          return undefined;
        }
        return new Date(value);
      },
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'labnotes',
      headerName: 'Lab Notes',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
  ];

  useEffect(() => {
    if (formik.values != null) {
      setlistresults(formik.values.elabtestresultlist);
    }
    console.log(formik.values);
  }, [formik.values]);

  return (
    <ReportDataGrid
      loading={loading}
      idcolumn='id'
      listresults={listresults ?? []}
      columnsInput={columns}
      customButtons={[
        <GridToolbarQuickFilter key='filter' />,
        <UploadToMedexLabCaseButton key='upload' />,
      ]}
      title='ELAB TEST RESULTS'
    />
  );
}
