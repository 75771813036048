import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useIncident } from './IncidentContext';
import SiloDataGrid from '../../utils/components/SiloDataGrid';
import { IncidentGroupsDataGrid } from '../../components/DashboardDataGrids/IncidentGroupsDataGrid';
import { useAuth } from '../../utils/auth/AuthService';
import { loadDashboardDataGridAPI } from '../../components/DashboardDataGrids/useDashboardDataGridAPI.hook';
import { DASHBOARD_SILO_SEQS, DashboardSilo } from '../Dashboard/dashboard.constants';
import { DashboardDataGridAPIResponse } from '../Dashboard/DashboardView';

const silo = {
  dashboardSiloName: 'Mass Casualty Incidents',
  endpoint: 'GetIncidentGroups',
  Component: React.memo(IncidentGroupsDataGrid),
  defaultSize: 6,
  dashboardSiloSeq: DASHBOARD_SILO_SEQS.MASS_CASUALTY_INCIDENTS,
  layoutOrder: -1,
  id: DASHBOARD_SILO_SEQS.MASS_CASUALTY_INCIDENTS,
};

const DVCSDashboard = () => {
  const { incident } = useIncident();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>([]);
  const [error, setError] = useState<any>([]);
  const auth = useAuth();

  const fetchDataForSilo = async (silo: DashboardSilo) => {
    try {
      const { data, error } = await loadDashboardDataGridAPI(
        silo.endpoint,
        auth.user!,
        auth.user?.jdxAccessList ?? []
      );
      setData(data);
      setError(error);
      setLoading(false);

      // return { data, error, loading: false };
    } catch (error) {
      setData([]);
      setError(error);
      setLoading(false);
      // return { data: [], error, loading: false };
    }
  };

  // Update the state with new data for a specific silo
  const updateSiloData = (siloSeq: string, update: Partial<DashboardDataGridAPIResponse>) => {
    setData((currentResponses: any) =>
      currentResponses.map((response: any) => {
        if (response.dashboardSiloSeq === siloSeq) {
          return { ...response, ...update };
        }
        return response;
      })
    );
  };

  useEffect(() => {
    setLoading(true);

    fetchDataForSilo(silo).then(({ data, error, loading }) => {
      updateSiloData(silo.dashboardSiloSeq, { data, error, loading });
    });
  }, []);

  return (
    <Box component='div' sx={{ p: 2 }}>
      <IncidentGroupsDataGrid isLoading={loading} data={data} height={700} />
    </Box>
  );
};

export default DVCSDashboard;
