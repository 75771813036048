import { Close, Edit, Save } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { ActionList } from '../../components/CaseView/CaseViewDateTimeField';
import CondensedDataGrid from '../../components/CondensedDataGrid';
import { useAuth } from '../../utils/auth/AuthService';
import { useIncident } from './IncidentContext';

interface GroupCaseOption {
  caseSeq: string;
  caseID: string;
  firstName: string;
  lastName: string;
}

interface IncidentGroupOption {
  incidentGroupSeq: string | null;
  incidentGroupId: string;
  incidentDescription: string;
  incidentDateTime: string | null;
  isCaseGrouped: boolean;
}

interface CaseList {
  casenumber: string;
  decedentname: string;
}

const matchCount = 20;

export default function IncidentCard() {
  const [caseOptions, setCaseOptions] = useState<GroupCaseOption[]>([]);
  const [selectedCases, setSelectedCases] = useState<GroupCaseOption[]>([]);
  const [loadingCases, setLoadingCases] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<CaseList | null>(null);
  const { incident } = useIncident();
  const [isEditing, setIsEditing] = useState(false);

  const { user } = useAuth();
  const { VITE_API_URL } = import.meta.env;

  useEffect(() => {
    console.log(incident);
  }, [incident]);

  const [selectedIncidentGroup, setSelectedIncidentGroup] = useState<IncidentGroupOption>({
    incidentGroupSeq: null,
    incidentGroupId: '',
    incidentDescription: '',
    incidentDateTime: null,
    isCaseGrouped: false,
  });

  const [casesOnIncidentGroup, setCasesOnIncidentGroup] = useState<any[]>([]);
  const [casesOnIncidentGroupLoading, setCasesOnIncidentGroupLoading] = useState(false);

  const handleConfirmDelete = (row: any) => {
    setCasesOnIncidentGroup((prev: any) =>
      prev.map((item: any) =>
        item.caseSeq === row?.caseSeq ? { ...item, isActive: false, isChanged: true } : item
      )
    );
    setShowConfirmation(false);
    setItemToDelete(null);
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedIncidentGroup(prevState => ({
      ...prevState,
      incidentDescription: event.target.value, // Update only the description
    }));
  };

  const fetchCaseOptions = debounce(async (caseIdOrLastName: string) => {
    if (caseIdOrLastName.includes('--') || caseIdOrLastName.trim() === '') {
      setCaseOptions([]);
      return;
    }

    const caseIdRegex =
      /^(\d{1,2})(?:(?<=\d{2})(-\d{0,2}){0,1})?(?:(?<=\d{2}-\d{2})(-\d{0,4}){0,1})?$/;

    let caseID: string | null = null;
    let lastName: string | null = null;

    if (caseIdRegex.test(caseIdOrLastName)) {
      caseID = caseIdOrLastName;
    } else {
      lastName = caseIdOrLastName;
    }

    setLoadingCases(true);

    try {
      const response = await fetch(
        `${VITE_API_URL}GetCaseMatchesFromCaseIdOrLastName?count=${matchCount}&caseID=${
          caseID ?? ''
        }&lastName=${lastName ?? ''}`,
        {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + user?.token,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch case options.');
      }

      const data: GroupCaseOption[] = await response.json();
      // Every option except ourselves
      // const filteredData = data.filter(
      //   option => option.caseSeq?.toUpperCase() !== caseSeq?.toUpperCase()
      // );
      // setCaseOptions(filteredData);
      setCaseOptions(data);
    } catch (error) {
      console.error(error);
      setCaseOptions([]);
    } finally {
      setLoadingCases(false);
    }
  }, 300);

  const fetchCasesOnIncidentGroup = async (incidentGroupSeq: string | undefined) => {
    if (!incidentGroupSeq) return;

    setCasesOnIncidentGroupLoading(true);
    fetch(VITE_API_URL + 'getcasesonincidentgroup?IncidentGroupSeq=' + incidentGroupSeq, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user?.token,
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setCasesOnIncidentGroup(data);
      })
      .catch(e => {
        alert(e);
      })
      .finally(() => {
        setCasesOnIncidentGroupLoading(false);
      });
  };

  useEffect(() => {
    fetchCasesOnIncidentGroup(incident?.incidentGroupSeq);
  }, [incident]);

  const columns = [
    {
      field: 'casenumber',
      headerName: 'CaseID',
      width: 150,
      flex: 1,
    },
    {
      field: 'menotified',
      headerName: 'Reported',
      width: 150,
      flex: 1,
      valueFormatter: (value: any) => {
        return value ? format(new Date(value), 'MM/dd/yyyy, HH:mm') : '';
      },
    },
    {
      field: 'decedentname',
      headerName: 'Decedent',
      width: 150,
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      width: 80,
      renderCell: (params: any) => {
        const onDeleteClick = (e: React.MouseEvent, row: any) => {
          e.stopPropagation();
          // setShowConfirmation(true);
          // setItemToDelete(row);
          handleConfirmDelete(row);
        };
        const jdxCode = params.row.casenumber.split('-')[0];
        const hasJdxAccess = user?.jdxAccessList.some(jdx => jdx.jdxCode === jdxCode);

        return (
          <div>
            {/* System-Admin and jurisdiction access user can remove the case from the group. (need to check on systems admin how this should work)*/}
            {/* (hasJdxAccess || user?.roleCheck(['451'])) */}
            {hasJdxAccess && (
              <IconButton
                onClick={e => onDeleteClick(e, params.row)}
                style={{
                  justifyContent: 'left',
                }}
                size='small'
                color='error'
              >
                <DeleteIcon fontSize='small' />
              </IconButton>
            )}
          </div>
        );
      },
    },
  ];

  const ConfirmationModal = () => {
    return (
      <Dialog
        open={showConfirmation}
        onClose={handleCancelDelete}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='responsive-dialog-title'>{'Confirm Void File'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove this case from group?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDelete} autoFocus>
            Yes
          </Button>
          <Button autoFocus onClick={handleCancelDelete}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Card sx={{ m: 2, position: 'relative' }}>
        <CardHeader
          title='Incident Cases'
          subheader={`Active Incident: ${
            incident === null ? 'None' : `${incident?.incidentDescription} (${incident?.groupID})`
          }`}
        />
        <Box component='div' sx={{ position: 'absolute', top: 0, right: 0, mt: 1, mr: 1 }}>
          <Stack direction='row' spacing={2}>
            <IconButton onClick={() => setIsEditing(!isEditing)} color='primary'>
              {isEditing ? <Save /> : <Edit />}
            </IconButton>

            {isEditing && (
              <IconButton onClick={() => setIsEditing(false)} color='error'>
                <Close />
              </IconButton>
            )}
          </Stack>
        </Box>
        <Divider sx={{ opacity: 0.8 }} />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id='incidentDescription'
                name='incidentDescription'
                label='Description of the Incident'
                variant='outlined'
                fullWidth
                disabled={!isEditing}
                type='text'
                value={incident?.incidentDescription ?? ''}
                onChange={handleDescriptionChange}
                required
                multiline
                inputProps={{
                  maxLength: 1000,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  name='incidentDateTime'
                  label='Date of the Incident'
                  value={incident?.incidentDateTime ? dayjs(incident?.incidentDateTime) : null}
                  disabled={!isEditing}
                  onChange={date => {
                    const localDate = date?.isValid()
                      ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss')
                      : null;
                    setSelectedIncidentGroup(prevState => ({
                      ...prevState,
                      incidentDateTime: localDate,
                    }));
                  }}
                  timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                  ampm={false}
                  slots={{
                    actionBar: ActionList,
                  }}
                  slotProps={{
                    field: { clearable: true },
                    textField: { id: 'incidentDateTime' },
                  }}
                  sx={{
                    minWidth: '100%',
                  }}
                />
              </LocalizationProvider>
            </Grid>

            {isEditing && (
              <>
                <Grid item xs={12}>
                  <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
                    <strong>Search for additional cases to be included in the Group:</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    disableCloseOnSelect
                    multiple
                    options={caseOptions.filter(option => {
                      const jdxCode = option.caseID.split('-')[0];
                      return user?.jdxAccessList.some(jdx => jdx.jdxCode === jdxCode);
                    })}
                    value={selectedCases}
                    onChange={(event, newValue) => setSelectedCases(newValue)}
                    isOptionEqualToValue={(option, value) => option.caseSeq === value.caseSeq}
                    getOptionLabel={option =>
                      `${option.caseID} -- ${option.lastName}, ${option.firstName}`
                    }
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<MdCheckBoxOutlineBlank />}
                          checkedIcon={<MdOutlineCheckBox />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.caseID} -- {option.lastName}, {option.firstName}
                      </li>
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          {...getTagProps({ index })}
                          label={`${option.caseID} -- ${option.lastName}, ${option.firstName}`}
                          key={option.caseSeq}
                        />
                      ))
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        label='Case ID or Last Name'
                        variant='outlined'
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loadingCases && <CircularProgress color='inherit' size={20} />}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                        onChange={event => fetchCaseOptions(event.target.value)}
                      />
                    )}
                    loading={loadingCases}
                    fullWidth
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Divider sx={{ borderColor: '#555', m: 0, pb: 0 }} />
              <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
                <strong>Cases in this group:</strong>
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <CondensedDataGrid
                loading={casesOnIncidentGroupLoading}
                listresults={casesOnIncidentGroup.filter(item => item.isActive) || []}
                columnsInput={columns}
                idcolumn='casenumber'
                gridcolor='#0288d1'
                gridheight={casesOnIncidentGroup?.length > 0 ? 'auto' : '100'}
                rowsperpage={10}
                tooltip='List of Grouped cases'
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <ConfirmationModal />
    </>
  );
}
