import { CancelRounded } from '@mui/icons-material';
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteOwnerState,
  AutocompleteProps,
  AutocompleteRenderOptionState,
  AutocompleteValue,
  Checkbox,
  Chip,
  CircularProgress,
  FilterOptionsState,
  TextField,
  TextFieldProps,
  createFilterOptions,
} from '@mui/material';
import { useFormikContext } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { useUnsavedChanges } from '../../Context/UnsavedChangesContext';
import { Option } from '../../views/Case/CaseViewModules/MortuaryModules/XrayRequestForm/xrayrequestform.constants';
import { useOptionsAPI } from '../api/useOptions.hook';
import { useAuth } from '../auth/AuthService';
import { AllRoles } from '../constants/AllRoles';

// Call this function once, ideally in your app's entry point

interface CaseViewOptionsAutocompleteProps<
  Value,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends React.ElementType
> extends Omit<
    AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>,
    'renderInput' | 'options'
  > {
  label: React.ReactNode;
  id?: string;
  authorizedToEdit: ((allRoles: typeof AllRoles) => string[]) | boolean;
  optionsEndpoint: string;
  formikField: string;
  textFieldProps?: TextFieldProps;
  onOptionChange?: (
    value: Multiple extends true ? Value[] : Value | null,
    allOptions: Option[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<Value>
  ) => void;
  customOptionsHandler?: (allOptions: Option[]) => Option[];
  mode?: 'single' | 'multiple' | 'checkbox';
  displaySelectedAtTop?: false;
  onlyUseOptionSeq?: boolean;
  onlyUseOptionName?: boolean;
  trackUnsavedChanges?: boolean;
}

export const CaseViewOptionsAutocomplete = <
  Value extends Option,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = 'div'
>({
  label,
  authorizedToEdit,
  optionsEndpoint,
  formikField,
  id = formikField,
  textFieldProps,
  onOptionChange,
  mode = 'single',
  displaySelectedAtTop = false,
  onlyUseOptionSeq = false,
  onlyUseOptionName = false,
  customOptionsHandler,
  trackUnsavedChanges = true,
  ...props
}: CaseViewOptionsAutocompleteProps<
  Value,
  Multiple,
  DisableClearable,
  FreeSolo,
  ChipComponent
>) => {
  const { user } = useAuth();
  const { setUnsavedChanges } = useUnsavedChanges();
  const formik = useFormikContext<any>();
  const { options: fetchedOptions = [], loading, error } = useOptionsAPI(optionsEndpoint);

  const options = useMemo(() => {
    if (customOptionsHandler) {
      return customOptionsHandler(fetchedOptions);
    }
    return fetchedOptions;
  }, [fetchedOptions, customOptionsHandler]);

  const userCanEdit = useMemo(() => {
    if (typeof authorizedToEdit === 'boolean') {
      return authorizedToEdit;
    }

    if (typeof authorizedToEdit === 'function') {
      const roles = authorizedToEdit(AllRoles);
      return user?.roleCheck(roles) || false;
    }

    return false;
  }, [user, authorizedToEdit]);

  const { value: fieldValue } = formik.getFieldProps(formikField);

  const definedFieldValue = useMemo(() => {
    if (onlyUseOptionSeq || onlyUseOptionName) {
      if (mode === 'multiple' || mode === 'checkbox') {
        if (!Array.isArray(fieldValue)) return [];

        return fieldValue
          .map(value =>
            options.find(opt =>
              onlyUseOptionSeq
                ? opt?.optionSeq?.toLowerCase() === value?.toLowerCase()
                : opt.optionName === value
            )
          )
          .filter(Boolean) as Value[];
      } else {
        // single mode
        return (
          options.find(opt =>
            onlyUseOptionSeq
              ? opt?.optionSeq?.toLowerCase() === fieldValue?.toLowerCase()
              : opt.optionName === fieldValue
          ) || null
        );
      }
    }

    // Default case: no transformation needed
    return fieldValue ?? (mode === 'multiple' || mode === 'checkbox' ? [] : null);
  }, [fieldValue, mode, onlyUseOptionSeq, onlyUseOptionName, options]);

  const handleChange = useCallback(
    (
      event: React.SyntheticEvent<Element, Event>,
      value: AutocompleteValue<Value, Multiple, DisableClearable, FreeSolo>,
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<Value>
    ) => {
      let newValue;

      if (onlyUseOptionSeq || onlyUseOptionName) {
        if (Array.isArray(value)) {
          newValue = value.map(v => {
            if (typeof v === 'string') return v;
            return onlyUseOptionSeq ? v.optionSeq : v.optionName;
          });
        } else {
          if (value === null) {
            newValue = null;
          } else if (typeof value === 'string') {
            newValue = value;
          } else {
            newValue = onlyUseOptionSeq ? (value as Value).optionSeq : (value as Value).optionName;
          }
        }
      } else {
        newValue = value;
      }

      formik.setFieldValue(formikField, newValue);

      if (trackUnsavedChanges) {
        setUnsavedChanges();
      }

      if (onOptionChange) {
        onOptionChange(
          value as Multiple extends true ? Value[] : Value | null,
          options,
          reason,
          details
        );
      }
    },
    [formik, formikField, onOptionChange, onlyUseOptionSeq, onlyUseOptionName]
  );

  const isOptionEqualToValue = useCallback(
    (option: Value, value: Value | string) => {
      if (onlyUseOptionSeq) {
        return (
          option?.optionSeq?.toLowerCase() ===
          (typeof value === 'string' ? value?.toLowerCase() : value?.optionSeq?.toLowerCase())
        );
      }
      if (onlyUseOptionName) {
        return option?.optionName === (typeof value === 'string' ? value : value?.optionName);
      }
      return option?.optionSeq?.toLowerCase() === (value as Value)?.optionSeq?.toLowerCase();
    },
    [onlyUseOptionSeq, onlyUseOptionName, options, customOptionsHandler]
  );

  const getOptionLabel = useCallback(
    (option: Value | string) => {
      if (typeof option === 'string') {
        const foundOption = options.find(o => o.optionSeq === option);
        return foundOption ? foundOption?.optionName : option;
      }

      return option?.isActive !== false ? option.optionName : `${option.optionName} (Inactive)`;
    },
    [options]
  );

  const renderLoadingAdornment = useCallback(
    (isLoading: boolean, adornment: React.ReactNode) => (
      <>
        {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
        {adornment}
      </>
    ),
    []
  );

  const renderAutocompleteInput = useCallback(
    (params: any, loading: boolean, error: boolean, label: React.ReactNode) => {
      const variant = params.variant || 'filled';
      return (
        <TextField
          {...params}
          label={label}
          variant={variant}
          multiline
          minRows={1}
          maxRows={4}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </div>
            ),
          }}
          error={Boolean(error)}
          helperText={error ? 'Failed to load options' : null}
          sx={{
            ...textFieldProps?.sx,
          }}
          {...textFieldProps}
          InputLabelProps={{
            ...params.InputLabelProps,
          }}
        />
      );
    },
    [loading, textFieldProps]
  );

  const renderOptionWithCheckbox = useCallback(
    (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: Value,
      state: AutocompleteRenderOptionState,
      ownerState: AutocompleteOwnerState<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>
    ) => {
      return (
        <li {...props}>
          <Checkbox
            icon={<MdCheckBoxOutlineBlank />}
            checkedIcon={<MdOutlineCheckBox />}
            style={{ marginRight: 8 }}
            checked={state.selected}
            onChange={event => event.stopPropagation()}
          />
          {option.optionName}
        </li>
      );
    },
    []
  );

  const groupBy = useCallback(
    (option: Value) => {
      const selectedValues = Array.isArray(definedFieldValue) ? definedFieldValue : [];
      const isSelected = selectedValues.some(
        o => o?.optionSeq?.toLowerCase() === option?.optionSeq?.toLowerCase()
      );
      return isSelected ? 'Selected' : 'Available';
    },
    [definedFieldValue]
  );

  const sortedOptions = useMemo(() => {
    if (Array.isArray(options)) {
      return options.slice().sort((a, b) => {
        const aSelected = Array.isArray(definedFieldValue)
          ? definedFieldValue.some(
              value => value?.optionSeq?.toLowerCase() === a?.optionSeq?.toLowerCase()
            )
          : definedFieldValue &&
            definedFieldValue?.optionSeq?.toLowerCase() === a?.optionSeq?.toLowerCase();

        const bSelected = Array.isArray(definedFieldValue)
          ? definedFieldValue.some(
              value => value?.optionSeq?.toLowerCase() === b?.optionSeq?.toLowerCase()
            )
          : definedFieldValue &&
            definedFieldValue?.optionSeq?.toLowerCase() === b?.optionSeq?.toLowerCase();

        if (aSelected && !bSelected) {
          return -1;
        }
        if (!aSelected && bSelected) {
          return 1;
        }
        return 0;
      });
    }

    return [];
  }, [options, definedFieldValue]);

  const filterOptions = createFilterOptions<Value>();

  const customFilterOptions = (options: Value[], state: FilterOptionsState<Value>) => {
    const activeOptions = options.filter(o => o.isActive !== false);
    return filterOptions(activeOptions, state);
  };

  return (
    <Autocomplete<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>
      fullWidth
      disabled={!formik.status.editing || !userCanEdit}
      id={id}
      groupBy={displaySelectedAtTop ? groupBy : undefined}
      loading={loading}
      options={displaySelectedAtTop ? (sortedOptions as Value[]) : (options as Value[])}
      filterOptions={customFilterOptions}
      value={definedFieldValue as AutocompleteValue<Value, Multiple, DisableClearable, FreeSolo>}
      isOptionEqualToValue={isOptionEqualToValue}
      onChange={handleChange}
      getOptionLabel={getOptionLabel}
      renderInput={params => renderAutocompleteInput(params, loading, Boolean(error), label)}
      suppressContentEditableWarning={true}
      suppressHydrationWarning={true}
      multiple={
        mode === 'multiple' || mode === 'checkbox' ? (true as Multiple) : (false as Multiple)
      }
      renderOption={mode === 'checkbox' ? renderOptionWithCheckbox : undefined}
      disableCloseOnSelect={mode === 'checkbox' || mode === 'multiple'}
      autoHighlight
      autoComplete
      renderTags={(v, getTagProps) => {
        return v.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            sx={{
              height: 'auto',
              '& .MuiChip-label': {
                display: 'flex',
                alignItems: 'center',
              },
              '& .MuiChip-deleteIcon': {
                fontSize: '16px',
              },
            }}
            label={option.optionName}
            deleteIcon={<CancelRounded />}
          />
        ));
      }}
      {...props}
    />
  );
};

CaseViewOptionsAutocomplete.defaultProps = {
  label: 'Unknown Field',
  id: 'Unknown Field ID',
  customOptionsHandler: undefined,
  authorizedToEdit: (r: typeof AllRoles) => [
    r.Investigator,
    r.Medical_Examiner,
    r.Administrative_Assistant,
    r.Historic_Dataentryonly,
    r.Systems_Admin,
  ],
};
