import { Snackbar, Alert, Typography } from '@mui/material';
import { useState } from 'react';
import SiloDataGrid from '../../utils/components/SiloDataGrid';
import { format } from 'date-fns';
import { GridColDef } from '@mui/x-data-grid-premium';
import { DashboardDataGridProps } from './useDashboardDataGridAPI.hook';
import GroupCasesDialog from '../../components/GroupCasesDialog';
import { AlertColor } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { Incident, useIncident } from '../../views/DVCS/IncidentContext';
import dayjs from 'dayjs';

interface SelectedRowType {
  incidentGroupSeq: string;
  groupID: string;
  incidentDescription: string;
  incidentDateTime: string;
  caseCount: string;
  isActive: boolean;
  createdBy: string;
  createdAt: string;
}

export function IncidentGroupsDataGrid({ data, isLoading, height = 200 }: DashboardDataGridProps) {
  const [selectedRow, setSelectedRow] = useState<SelectedRowType | null>(null);
  const [groupDialogOpen, setGroupDialogOpen] = useState(false);
  const [groupSnackbar, setGroupSnackbar] = useState<{
    open: boolean;
    severity: AlertColor;
    message: string;
  }>({
    open: false,
    severity: 'success',
    message: '',
  });
  const location = useLocation();
  const navigate = useNavigate();
  const { incident, setIncident } = useIncident();

  const handleCloseGroupSnackbar = () => {
    setGroupSnackbar(prev => ({ ...prev, open: false }));
  };

  const handleGroupActionComplete = (status: 'success' | 'failure', groupId?: string) => {
    if (status === 'success') {
      setGroupSnackbar({
        open: true,
        severity: 'success',
        message: `Successfully updated the, Group ID: ${groupId}`,
      });
    } else {
      setGroupSnackbar({
        open: true,
        severity: 'error',
        message: 'An error occurred, unable to update the group.',
      });
    }
  };

  const handleRowClick = (params: SelectedRowType) => {
    if (!location.pathname.startsWith('/dvcs')) {
      setSelectedRow(params);
      setGroupDialogOpen(true);
    } else {
      setIncident(params as Incident);
      navigate('/dvcs/cases');
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'groupID',
      headerName: 'Group ID',
      width: 150,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
    },
    {
      field: 'incidentDescription',
      headerName: 'Description',
      width: 300,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
    },
    {
      field: 'incidentDateTime',
      headerName: 'Date/Time',
      width: 220,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
      type: 'dateTime',
      valueGetter: value => {
        return value != null ? new Date(value) : null;
      },
      valueFormatter: value => {
        return value ? format(new Date(value), 'MM/dd/yyyy, HH:mm') : null;
      },
    },
    {
      field: 'caseCount',
      headerName: 'Case Count',
      width: 100,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
    },
    {
      field: 'isActive',
      headerName: 'Active',
      width: 75,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      width: 150,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
    },
    {
      field: 'createdAt',
      headerName: 'Created At:',
      width: 150,
      editable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'header-theme',
      valueGetter: value => {
        return value != null ? new Date(value) : null;
      },
      valueFormatter: value => {
        return value ? format(new Date(value), 'MM/dd/yyyy, HH:mm') : null;
      },
    },
  ];

  return (
    <>
      <Snackbar
        open={groupSnackbar.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ top: 5 }}
        autoHideDuration={5000}
        onClose={handleCloseGroupSnackbar}
      >
        <Alert
          onClose={handleCloseGroupSnackbar}
          severity={groupSnackbar.severity}
          variant='filled'
          sx={{ width: '100%' }}
        >
          <Typography variant='body1' fontWeight={500}>
            {groupSnackbar.message}
          </Typography>
        </Alert>
      </Snackbar>

      <SiloDataGrid
        loading={isLoading}
        listresults={data}
        columnsInput={columns}
        idcolumn='incidentGroupSeq'
        gridheight={height}
        gridcolor='#e74c3c'
        title='Grouped Cases (Incidents)'
        tooltip='Incidents that involve more than 1 decedent'
        casecount
        rowsperpage={50}
        filterModel={undefined}
        columnsVisibility={undefined}
        onRowClick={(params: any) => handleRowClick(params.row)}
        onCaseIDClick={() => {}}
      />

      {groupDialogOpen && selectedRow && (
        <GroupCasesDialog
          open={groupDialogOpen}
          setOpen={setGroupDialogOpen}
          incidentGroupSeq={selectedRow.incidentGroupSeq}
          isGroupEditing={true}
          onGroupActionComplete={handleGroupActionComplete}
        />
      )}
    </>
  );
}
