import React, { useState } from 'react';
import {
  Typography,
  TextField,
  Button,
  Box,
  Card,
  CardHeader,
  CardContent,
  Stack,
  Divider,
} from '@mui/material';
import { useIncident } from './IncidentContext';

const CallCenterInterface = () => {
  const { incident } = useIncident();
  const [formData, setFormData] = useState({
    callerName: '',
    missingPersonName: '',
    contactInfo: '',
    lastLocation: '',
    notes: '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    console.log('Logged Data:', { ...formData, incident });
    alert(`Call logged successfully for incident: ${incident?.incidentDescription}`);
    setFormData({
      callerName: '',
      missingPersonName: '',
      contactInfo: '',
      lastLocation: '',
      notes: '',
    });
  };

  return (
    <Card sx={{ m: 2 }}>
      <CardHeader
        title='Call Center Interface'
        subheader={`Active Incident: ${
          incident === null ? 'None' : `${incident?.incidentDescription} (${incident?.groupID})`
        }`}
      />
      <Divider sx={{ opacity: 0.8 }} />
      <CardContent>
        <Stack spacing={2}>
          <TextField
            label='Caller Name'
            name='callerName'
            value={formData.callerName}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            label='Missing Person Name'
            name='missingPersonName'
            value={formData.missingPersonName}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            label='Contact Information'
            name='contactInfo'
            value={formData.contactInfo}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            label='Last Known Location'
            name='lastLocation'
            value={formData.lastLocation}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            label='Additional Notes'
            name='notes'
            value={formData.notes}
            onChange={handleInputChange}
            multiline
            rows={3}
            fullWidth
          />
          <Button variant='contained' onClick={handleSubmit}>
            Log Call
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CallCenterInterface;
