import FileDownloadIcon from '@mui/icons-material/FileDownload';
import InfoIcon from '@mui/icons-material/Info';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import {
  Box,
  CircularProgress,
  Fab,
  IconButton,
  Pagination,
  PaginationItem,
  Paper,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import {
  DataGridPremium,
  GridAlignment,
  GridColDef,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridApiRef,
  useGridSelector,
} from '@mui/x-data-grid-premium';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import '../../App.css';
import { CaseIDCell } from '../../components/DashboardDataGrids/CaseIDCell';

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color='primary'
      variant='outlined'
      shape='rounded'
      page={page + 1}
      count={pageCount}
      renderItem={props2 => (
        <PaginationItem
          {...props2}
          sx={{
            fontFamily: 'DataGrid, sans-serif',
            fontSize: '0.75rem',
            padding: '0px',
            minWidth: '15px',
            height: '15px',
          }}
        />
      )}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      sx={{
        mr: 0,
        ml: 0,
        '& .MuiPaginationItem-root': {
          fontSize: '0.75rem',
          padding: '0px',
          minWidth: '15px',
          height: '15px',
        },
      }}
    />
  );
}

interface SiloDataGridProps {
  loading: boolean;
  listresults: any[];
  columnsInput: GridColDef[];
  title: string;
  gridheight: number;
  gridrowheight?: boolean;
  tooltip: string;
  gridcolor: string;
  idcolumn: string;
  casecount: boolean;
  rowsperpage?: number;
  filterModel?: any;
  sidecontent?: React.ReactNode;
  columnsVisibility?: Record<string, boolean>;
  onRowClick?: (params: any) => void;
  onCaseIDClick?: (params: any) => void;
  disableRowSelectionOnClick?: boolean;
  sorting?: any;
  headerClickUrl?: string;
  reloading?: boolean;
  refreshSeconds?: number;
}

export default function SiloDataGrid({
  loading,
  listresults,
  columnsInput,
  title,
  gridheight,
  gridrowheight = false,
  tooltip,
  gridcolor,
  idcolumn,
  casecount,
  rowsperpage = 5,
  filterModel,
  sidecontent = null,
  columnsVisibility,
  onRowClick,
  onCaseIDClick,
  disableRowSelectionOnClick = true,
  sorting,
  headerClickUrl,
  reloading = false,
  refreshSeconds = -1,
}: SiloDataGridProps) {
  const [updatedColumns, setUpdatedColumns] = useState<GridColDef[]>([]);
  const [updatedExpandedColumns, setUpdatedExpandedColumns] = useState<GridColDef[]>([]);
  const [expanded, setExpanded] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: rowsperpage,
    page: 0,
  });
  const apiRef = useGridApiRef();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleCaseClick = (event: React.MouseEvent, data: any) => {
    navigate('/caseview', {
      state: { cmscaseid: data.formattedValue, caseSeq: data.row.caseseq },
    });
  };

  const toggleExpandDataGrid = () => {
    setExpanded(!expanded);
  };

  const handleExportToCSV = (fileName = 'dashboardsilo.csv') => {
    if (apiRef.current && apiRef.current.state && apiRef.current.state.columns) {
      const columnVisibilityModel = apiRef.current.state.columns.columnVisibilityModel;
      const columnsToExport = updatedColumns.filter(
        col => col.field !== 'rownumber' && columnVisibilityModel[col.field] !== false
      );
      const headers = columnsToExport.map(col => col.headerName || col.field).join(',');
      const rows = apiRef.current.getRowModels();
      const csvContent = [headers];

      rows.forEach((row: any) => {
        const rowData = columnsToExport
          .map(col => {
            const value = row[col.field];
            return typeof value === 'string' ? `"${value.replace(/"/g, '""')}"` : value;
          })
          .join(',');
        csvContent.push(rowData);
      });

      const csvString = csvContent.join('\n');
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      console.error('Grid API not available or column state is undefined');
    }
  };

  useEffect(() => {
    var count = 1;
    for (var row of listresults) {
      row.rownumber = count;
      count += 1;
    }

    const newColumnsInput: GridColDef[] = columnsInput.map(column => ({
      ...column,
      align: (column.align as GridAlignment) || 'left',
      headerAlign: (column.headerAlign as GridAlignment) || 'left',
      flex: 1,
      minWidth: column.width || undefined,
    }));

    newColumnsInput.forEach(column => {
      if (column.field === 'caseid' || column.field === 'caseID' || column.field === 'groupID') {
        column.width = 140;
        column.flex = undefined;
        column.renderCell = (params: any) => (
          <CaseIDCell params={params} onCaseIDClick={onCaseIDClick} />
        );
      }

      if (column.field === 'id' || column.field === 'ID') {
        column.hideable = false;
      }
    });

    const exColumnsTemp: GridColDef[] = [
      {
        field: 'rownumber',
        headerName: '',
        width: 65,
        editable: false,
        align: 'left',
        headerAlign: 'left',
        headerClassName: 'header-theme',
        disableColumnMenu: true,
        disableExport: true,
      },
      ...newColumnsInput,
    ];

    setUpdatedColumns(newColumnsInput);
    setUpdatedExpandedColumns(exColumnsTemp);

    // Check if apiRef is correctly set up
    if (apiRef.current) {
      console.log('Grid API is initialized:', apiRef.current);
    } else {
      console.error('Grid API is not initialized');
    }
  }, [listresults, columnsInput]);

  const headerButtonsStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
  };

  return loading ? (
    <Stack spacing={0}>
      <Skeleton
        variant='rounded'
        animation='wave'
        height={30}
        sx={{ borderRadius: '10px 10px 3px 3px' }}
      />
      <Skeleton variant='text' animation='wave' sx={{ fontSize: '2rem' }} />
      <Skeleton
        variant='rounded'
        animation='wave'
        height={gridheight - 40}
        sx={{ borderRadius: '3px 3px 10px 10px' }}
      />
    </Stack>
  ) : expanded ? (
    <Box
      sx={{
        position: 'fixed',
        width: '100%',
        height: { xs: 'calc(100% - 5rem)', lg: '99%' },
        top: 59,
        left: 0,
        zIndex: theme => theme.zIndex.drawer + 1,
        '& .MuiDataGrid-columnHeaders': {
          borderRadius: 0,
          backgroundColor: gridcolor,
          border: 'none',
        },
        backgroundColor: theme.palette.background.paper,
      }}
      component={Paper}
    >
      <ThemeProvider theme={theme}>
        <Fab
          color='primary'
          size='small'
          sx={{
            position: 'fixed',
            top: 105,
            right: 25,
            color: 'white',
          }}
          onClick={toggleExpandDataGrid}
        >
          <OpenInFullIcon fontSize='small' />
        </Fab>
        <Fab
          color='primary'
          size='small'
          sx={{
            position: 'fixed',
            bottom: { xs: 105, lg: 25 },
            right: 25,
            width: 100,
            borderRadius: 15,
            color: 'white',
          }}
          onClick={() => handleExportToCSV()}
        >
          Export
        </Fab>
      </ThemeProvider>
      <DataGridPremium
        getRowId={row => row[idcolumn]}
        columns={updatedExpandedColumns}
        rows={listresults}
        apiRef={apiRef}
        sx={{
          border: 'none',
          borderRadius: '0 0 2px 2px',
          '& .MuiDataGrid-cell': {
            borderRight: '1px solid #ddd',
            fontFamily: 'DataGrid, sans-serif',
            fontWeight: 500,
            wordBreak: 'break-all',
          },
          '& .MuiDataGrid-cell:last-child': {
            borderRight: 'none',
          },
          '& .header-theme': {
            borderRight: '1px solid #ddd',
          },
          '& .header-theme:last-child': {
            borderRight: 'none',
          },
          '& .MuiDataGrid-iconSeparator': {
            display: 'none',
          },
          '& .MuiDataGrid-footerContainer': {
            minHeight: '30px', // Override the minimum height
            height: '30px',
          },
        }}
        getRowHeight={() => (gridrowheight ? 'auto' : 28)}
        columnHeaderHeight={28}
        initialState={{
          columns: {
            columnVisibilityModel: columnsVisibility,
          },
        }}
        localeText={{
          noRowsLabel: '',
        }}
        slotProps={{
          pagination: {
            sx: {
              minHeight: '25px', // Adjust the minimum height
              height: '30px', // Adjust the height
              paddingTop: '0px',
              paddingBottom: '0px',
              marginTop: '0px',
              marginBottom: '0px',
            },
          },
        }}
      />
    </Box>
  ) : (
    <Box
      component='div'
      sx={{
        '& .header-theme': {
          border: 'none',
          borderRadius: '0px',
        },
        '& .MuiDataGrid-columnHeaders': {
          borderRadius: 0,
        },
      }}
    >
      <Paper
        sx={{
          borderRadius: 2,
        }}
        elevation={3}
      >
        <Box component='div' sx={{ display: sidecontent != null ? 'flex' : 'block' }}>
          <Box
            component='div'
            sx={{
              display: 'block',
              width: sidecontent !== null ? 'calc(100% - 10rem)' : '100%',
            }}
          >
            <Box
              component='div'
              sx={{
                display: 'flex',
                backgroundColor: gridcolor,
                borderRadius: '6px 6px 0px 0px',
                cursor: headerClickUrl ? 'pointer' : 'default',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0.2rem 0.5rem',
              }}
              onClick={() => {
                if (headerClickUrl) {
                  navigate(headerClickUrl);
                }
              }}
            >
              <Typography
                variant='h1'
                sx={{
                  fontSize: '11pt',
                  fontFamily: 'DataGrid, sans-serif',
                  fontWeight: 400,
                  color: 'white',
                }}
              >
                {casecount ? `(${listresults.length}) ` : ''}
                <span dangerouslySetInnerHTML={{ __html: title }} />

                {reloading && (
                  <Tooltip arrow title={<Typography>Refreshing data, please wait</Typography>}>
                    <CircularProgress
                      size='11pt'
                      sx={{
                        color: 'white',
                        ml: 1,
                        fontFamily: 'DataGrid, sans-serif',
                        fontWeight: 400,
                      }}
                    />
                  </Tooltip>
                )}
              </Typography>
              <div style={headerButtonsStyle}>
                <IconButton
                  size='small'
                  sx={{ color: 'white', opacity: 0.65, mr: 1 }}
                  onClick={(event: { stopPropagation: () => void }) => {
                    event.stopPropagation();
                    handleExportToCSV();
                  }}
                >
                  <FileDownloadIcon fontSize='small' />
                </IconButton>
                <Tooltip
                  title={
                    <Stack spacing={1}>
                      <Typography component='span' dangerouslySetInnerHTML={{ __html: tooltip }} />
                      {refreshSeconds > -1 && (
                        <Typography>{`Data auto refreshes every ${refreshSeconds} seconds`}</Typography>
                      )}
                    </Stack>
                  }
                  componentsProps={{
                    tooltip: {
                      sx: {
                        maxWidth: 500,
                      },
                    },
                  }}
                >
                  <IconButton
                    size='small'
                    sx={{ color: 'white', opacity: 0.65 }}
                    onClick={(event: { stopPropagation: () => void }) => {
                      event.stopPropagation();
                    }}
                  >
                    <InfoIcon fontSize='small' />
                  </IconButton>
                </Tooltip>
                <IconButton
                  size='small'
                  sx={{ color: 'white', opacity: 0.65, ml: 1 }}
                  onClick={(event: { stopPropagation: () => void }) => {
                    event.stopPropagation();
                    toggleExpandDataGrid();
                  }}
                >
                  <OpenInFullIcon fontSize='small' />
                </IconButton>
              </div>
            </Box>
            <DataGridPremium
              getRowId={row => row[idcolumn]}
              columns={updatedColumns}
              rows={listresults}
              pagination
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
              apiRef={apiRef}
              disableDensitySelector={true}
              disableColumnSelector={true}
              disableColumnMenu={true}
              sx={{
                width: '100%',
                height: gridheight,
                border: 'none',
                borderRadius: sidecontent == null ? '0 0 2px 2px' : '0 0 2px 2px',
                '& .MuiDataGrid-cell': {
                  borderRight: '1px solid #ddd',
                  fontFamily: 'DataGrid, sans-serif',
                  fontWeight: 500,
                  wordBreak: 'break-all',
                  fontSize: '0.700rem',
                },
                '& .MuiDataGrid-cell:last-child': {
                  borderRight: 'none',
                },
                '& .header-theme': {
                  borderRight: '0px solid #ccc',
                },
                '& .header-theme:last-child': {
                  borderRight: 'none',
                },
                '& .MuiDataGrid-iconSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-footerContainer': {
                  minHeight: '20px', // Override the minimum height
                  height: '20px',
                },
              }}
              slots={{
                pagination: CustomPagination,
              }}
              getRowHeight={() => (gridrowheight ? 'auto' : 30)}
              columnHeaderHeight={32}
              disableRowSelectionOnClick={disableRowSelectionOnClick}
              onRowClick={onRowClick}
              initialState={{
                columns: {
                  columnVisibilityModel: columnsVisibility,
                },
                sorting: sorting,
              }}
              localeText={{
                noRowsLabel: '',
              }}
              slotProps={{
                pagination: {
                  sx: {
                    minHeight: '25px', // Adjust the minimum height
                    height: '25px', // Adjust the height
                    paddingTop: '0px',
                    paddingBottom: '0px',
                    marginTop: '0px',
                    marginBottom: '0px',
                  },
                },
              }}
            />
          </Box>
          {sidecontent != null ? (
            <Box component='div' sx={{ marginY: 'auto' }}>
              {sidecontent}
            </Box>
          ) : (
            <div></div>
          )}
        </Box>
      </Paper>
    </Box>
  );
}
