import { GridToolbarQuickFilter } from '@mui/x-data-grid-premium';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { NavLink } from 'reactstrap';
import { useAuth } from '../../../../utils/auth/AuthService';
import ReportDataGrid from '../../../../utils/components/ReportDataGrid/ReportDataGrid';

export default function ELabPMCaseListView() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [listresults, setlistresults] = useState([]);

  const { VITE_API_URL } = import.meta.env;

  const [selectedCaseNumer, setSelectedCaseNumber] = useState('');
  const columns = [
    {
      field: 'id',
      headerName: '',
      flex: 1,
      minWidth: 125,
      maxWidth: 125,
      hidden: true,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'elabcasenumber',
      headerName: 'eLab No.',
      minWidth: 125,
      maxWidth: 125,
      flex: 1,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
      renderCell: params => {
        return (
          <NavLink
            //variant="contained"
            color='primary'
            onClick={event => {
              handleELabCaseClick(event, params);
            }}
          >
            {params.formattedValue}
          </NavLink>
        );
      },
    },
    {
      field: 'mdxlabcaseid',
      headerName: 'MedexLab CaseID',
      flex: 1,
      minWidth: 125,
      maxWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
      renderCell: params => {
        return (
          <NavLink
            //variant="contained"
            color='primary'
            onClick={event => {
              handleCaseClick(event, params);
            }}
          >
            {params.formattedValue}
          </NavLink>
        );
      },
    },
    {
      field: 'decedentname',
      headerName: 'Decedent/Donor',
      flex: 1,
      // minWidth: 150,
      // maxWidth: 170,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'agencyname',
      headerName: 'Agency',
      flex: 1,
      // minWidth: 200,
      // maxWidth: 200,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'createddt',
      headerName: 'Created On',
      flex: 1,
      minWidth: 100,
      maxWidth: 100,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
      renderCell: params => {
        const dateObj = new Date(params.value);
        const formattedDate = dateObj.toLocaleDateString(); // formats date based on locale, adjust format as needed
        return <span>{formattedDate}</span>;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 175,
      maxWidth: 175,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
    {
      field: 'notes',
      headerName: 'Internal Comments',
      flex: 1,
      minWidth: 150,
      editable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'header-theme',
    },
  ];

  const handleCaseClick = (event, data) => {
    // window.location.href = '/caseview?id=' + data.formattedValue;
    navigate('/caseview', { state: { cmscaseid: data.formattedValue } });
  };
  const handleELabCaseClick = (event, data) => {
    navigate('/elabpmcaseview', { state: { elabcasenumber: data.formattedValue } });
  };

  const fetchELabTestResults = async () => {
    // let formData = new FormData();
    // formData.append('CASENUMBER', JSON.stringify('07-23-2344'));
    var caseNumber = '07-23-2344';

    fetch(VITE_API_URL + `GetELabTestResults?CASENUMBER=${caseNumber}`, {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        console.log('the elabtestresult: ', data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  useEffect(() => {
    if (user === null) return;

    setLoading(true);

    if (user && user.token) {
      fetchELabTestResults();

      fetch(VITE_API_URL + 'getElabPMCaseList', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user.token,
        },
      })
        .then(res => {
          if (res.status == 401) {
            throw new Error('You unauthorized to use this tool');
          } else if (res.status >= 400) {
            throw new Error('An error occured');
          }
          return res.json();
        })
        .then(data => {
          setlistresults(data);
          setLoading(false);
        })
        .catch(e => {
          alert(e);
        });
    } else {
      console.log('user or token is null');
    }
  }, [user]);

  return (
    <ReportDataGrid
      idcolumn='id'
      loading={loading}
      listresults={listresults}
      columnsInput={columns}
      title=''
      customButtons={[<GridToolbarQuickFilter key='filter' />]}
    />
  );
}
