// src/SuspectedDrugDeathsGrid.js
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Container,
  Divider,
  Paper,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import { useAuth } from '../../../utils/auth/AuthService';
import ReportDataGrid from '../../../utils/components/ReportDataGrid/ReportDataGrid';

/* ***NOTE***
     This grid is unique in terms of its columns object... The JSON that is returned by the Python Script & the Axios service
     is the only method that leaves the uppercase/lowercase syntax provided by the SQL query.
     All the 'field' values in the columns object match what the SQL query produces. To my knowledge, this is the only datagrid
     that functions in this way. 
     - Kevin
*/

export default function MonthlySuspectedDrugsView() {
  const { user } = useAuth();
  const [year, setYear] = useState('');
  const [loading, setloading] = useState(false);
  const [listresults, setListResults] = useState([]);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const [resultsVisible, setResultsVisible] = useState(false);

  // Load environment variables
  const baseUrl = import.meta.env.VITE_FASTAPI_URL;

  // Fetch data based on user input year
  const fetchData = async () => {
    setloading(true);
    setResultsVisible(true);
    try {
      const response = await axios.get(
        `${baseUrl}reports/general_reports/monthly_suspected_drug_deaths/${year}`
      );
      console.log('response data: ', response.data);
      setListResults(response.data);
      setError(null);
    } catch (error) {
      setError(
        'Error fetching data. Please ensure the server is running and the input year is valid.'
      );
      setListResults({});
    }
    console.log(listresults);
    setError(null);
    await setloading(false);
  };

  const handleSubmit = event => {
    if (year.length !== 4) {
      setError('Year must be exactly 4 digits');
      return;
    }
    fetchData();
  };

  // Columns for each county, as well as the Case Year and Month, with the totals from all counties at the end for each row
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      hide: true,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'MonthName',
      headerName: 'Month Name',
      width: 150,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'CaseYear',
      headerName: 'Case Year',
      width: 150,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'Atlantic',
      headerName: 'Atlantic',
      width: 150,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'Bergen',
      headerName: 'Bergen',
      width: 150,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'Burlington',
      headerName: 'Burlington',
      width: 150,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'Camden',
      headerName: 'Camden',
      width: 150,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'CapeMay',
      headerName: 'Cape May',
      width: 150,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'Cumberland',
      headerName: 'Cumberland',
      width: 150,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'Essex',
      headerName: 'Essex',
      width: 150,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'Gloucester',
      headerName: 'Gloucester',
      width: 150,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'Hudson',
      headerName: 'Hudson',
      width: 150,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'Hunterdon',
      headerName: 'Hunterdon',
      width: 150,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'Mercer',
      headerName: 'Mercer',
      width: 150,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'Middlesex',
      headerName: 'Middlesex',
      width: 150,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'Monmouth',
      headerName: 'Monmouth',
      width: 150,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'Morris',
      headerName: 'Morris',
      width: 150,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'Ocean',
      headerName: 'Ocean',
      width: 150,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'Passaic',
      headerName: 'Passaic',
      width: 150,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'Salem',
      headerName: 'Salem',
      width: 150,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'Somerset',
      headerName: 'Somerset',
      width: 150,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'Sussex',
      headerName: 'Sussex',
      width: 150,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'Union',
      headerName: 'Union',
      width: 150,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'Warren',
      headerName: 'Warren',
      width: 150,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'NJStateCase',
      headerName: 'NJ State Case',
      width: 150,
      editable: false,
      headerAlign: 'left',
    },
    {
      field: 'Total',
      headerName: 'Total',
      width: 150,
      editable: false,
      headerAlign: 'left',
    },
  ];

  return (
    <Card
      raised
      sx={{
        my: 3,
        mx: 'auto',
        width: { xs: '95%', lg: '95%' },
      }}
    >
      <CardHeader
        title='Monthly Suspected Drugs Report'
        sx={{
          mx: 'auto',
          textAlign: 'center',
          color: theme.palette.primary.main,
        }}
        action={
          <Tooltip
            sx={{ color: theme.palette.text.primary }}
            title='This Report Lists the # of cases (in each county) marked as "Suspected Drug Overdose" by Investigators'
            placement='right-end'
          >
            <InfoIcon />
          </Tooltip>
        }
      />

      <Divider variant='middle' sx={{ my: 2, opacity: 1 }} />

      <CardContent>
        <Container>
          <Box
            my={2}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <TextField
              label='Year'
              value={year}
              onChange={e => setYear(e.target.value.replace(/\D/g, ''))}
              inputProps={{
                maxLength: 4,
              }}
            />
            <Button
              variant='contained'
              color='primary'
              onClick={handleSubmit}
              style={{ marginLeft: '10px' }}
            >
              Search
            </Button>
          </Box>

          {error && <div style={{ color: 'red' }}>{error}</div>}

          <Collapse in={resultsVisible} sx={{ mt: resultsVisible ? 4 : 0 }}>
            <ReportDataGrid
              getRowId={row => row.id}
              loading={loading}
              listresults={listresults}
              idcolumn='id'
              columnsInput={columns}
              title='Monthly Suspected Drugs Report'
              chatcontent={
                <Paper
                  elevation={3}
                  sx={{
                    p: '10px',
                    px: '20px',
                    borderRadius: '20px',
                    width: 'max-content',
                  }}
                >
                  <div style={{ textAlign: 'center' }}>
                    I've listed the monthly suspected drugs numbers below by-county by-month... you
                    can also export them in CSV format
                  </div>
                </Paper>
              }
            />
          </Collapse>
        </Container>
      </CardContent>
    </Card>
  );
}
