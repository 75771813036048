import dayjs, { Dayjs } from 'dayjs';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from '../../utils/auth/AuthService';
import { DASHBOARD_SILO_SEQS, DashboardSilo } from '../Dashboard/dashboard.constants';
import { loadDashboardDataGridAPI } from '../../components/DashboardDataGrids/useDashboardDataGridAPI.hook';
import { DashboardDataGridAPIResponse } from '../Dashboard/DashboardView';
import { IncidentGroupsDataGrid } from '../../components/DashboardDataGrids/IncidentGroupsDataGrid';

export interface Incident {
  caseCount: number;
  createdBy: string | null;
  createdOn: Dayjs | null;
  groupID: string;
  incidentDateTime: Dayjs | null;
  incidentDescription: string | null;
  incidentGroupSeq: string;
  isActive: boolean;
}

interface IncidentContextProps {
  incident: Incident | null;
  setIncident: React.Dispatch<React.SetStateAction<Incident | null>>;
  incidentOptions: Incident[];
}

const silo = {
  dashboardSiloName: 'Mass Casualty Incidents',
  endpoint: 'GetIncidentGroups',
  Component: React.memo(IncidentGroupsDataGrid),
  defaultSize: 6,
  dashboardSiloSeq: DASHBOARD_SILO_SEQS.MASS_CASUALTY_INCIDENTS,
  layoutOrder: -1,
  id: DASHBOARD_SILO_SEQS.MASS_CASUALTY_INCIDENTS,
};

const IncidentContext = createContext<IncidentContextProps | undefined>(undefined);

export const IncidentProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [incident, setIncident] = useState<Incident | null>(null);
  const [loading, setLoading] = useState(true);
  const [incidentOptions, setIncidentOptions] = useState<Incident[]>([]);
  const [error, setError] = useState<any>([]);
  const auth = useAuth();

  const fetchDataForSilo = async (silo: DashboardSilo) => {
    try {
      const { data, error } = await loadDashboardDataGridAPI(
        silo.endpoint,
        auth.user!,
        auth.user?.jdxAccessList ?? []
      );
      setIncidentOptions(data as Incident[]);
      setError(error);
      setLoading(false);
    } catch (error) {
      setIncidentOptions([]);
      setError(error);
      setLoading(false);
    }
  };

  // Update the state with new data for a specific silo
  const updateSiloData = (siloSeq: string, update: Partial<DashboardDataGridAPIResponse>) => {
    setIncidentOptions((currentResponses: any) =>
      currentResponses.map((response: any) => {
        if (response.dashboardSiloSeq === siloSeq) {
          return { ...response, ...update };
        }
        return response;
      })
    );
  };

  useEffect(() => {
    setLoading(true);

    fetchDataForSilo(silo).then(({ data, error, loading }) => {
      updateSiloData(silo.dashboardSiloSeq, { data, error, loading });
    });
  }, []);

  return (
    <IncidentContext.Provider value={{ incident, setIncident, incidentOptions }}>
      {children}
    </IncidentContext.Provider>
  );
};

export const useIncident = () => {
  const context = useContext(IncidentContext);
  if (!context) {
    throw new Error('useIncident must be used within an IncidentProvider');
  }
  return context;
};
