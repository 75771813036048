import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import MenuIcon from '@mui/icons-material/Menu';
import Refresh from '@mui/icons-material/Refresh';
import SaveIcon from '@mui/icons-material/Save';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Hidden,
  IconButton,
  Slide,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import CryptoJS from 'crypto-js';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useAuth } from '../../utils/auth/AuthService';
import { PrintRIMEButton } from '../../utils/components/PrintRRIMEPDF';
import { MEAction } from '../../utils/constants/MEAction';
import { ObjectionResolution } from '../../utils/constants/constants';
import { CaseViewAssignmentsDialog } from './CaseViewAssignmentsDialog';
import CaseViewGroupCasesDialog from '../../components/GroupCasesDialog';
import CaseViewTimeline from './CaseViewTimeline';
import ForceCaseUnlockDialog from './ForceCaseUnlockDialog';

function getValueOrDefault(value) {
  if (value === null || value === undefined) {
    return undefined;
  }

  if (typeof value === 'string' && value.trim() === '') {
    return undefined;
  }

  if (typeof value === 'object' && Object.keys(value).length === 0) {
    return undefined;
  }

  return value;
}

function getMeActionChipColor(meAction) {
  switch (meAction) {
    case 'Accepted':
      return '#2d2';
    case 'Release':
      return '#f33';
    case 'Facilitated Release':
      return '#fa3';
    case 'Storage Only':
      return '#66f';
    case 'Transfer':
      return '#94b';
    default:
      return '#e27';
  }
}

function getMODChipColor(MOD) {
  switch (MOD) {
    case 'Natural':
      return '#2d2';
    case 'Homicide':
      return '#f33';
    case 'Suicide':
      return '#f33';
    case 'Pending':
      return '#ffbf00'; // Amber color
    default:
      return '#e27';
  }
}

const useStyles = makeStyles(theme => ({
  '@keyframes float': {
    '0%': {
      transform: 'translateY(0) scale(1.4)',
    },
    '50%': {
      transform: 'translateY(-10px) scale(1.5)',
    },
    '100%': {
      transform: 'translateY(0) scale(1.4)',
    },
  },
  iconButton: {
    // position: 'fixed',
    // bottom: theme.spacing(2),
    // right: theme.spacing(2),
    animation: `$float 2s ease-in-out infinite`,
  },
}));

export default function CaseViewHeader({
  setHeaderHeight,
  setMenuOpen,
  currentTab,
  setCurrentTab,
  getCMSCaseData,
  showErrorAlert,
  setShowErrorAlert,
  validationErrors,
}) {
  const formik = useFormikContext();
  const theme = useTheme();
  const headerRef = useRef();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [timelineModal, setTimelineModal] = useState(false);
  const [groupDialogOpen, setGroupDialogOpen] = useState(false);
  const [groupedCasesDialogOpen, setGroupedCasesDialogOpen] = useState(false);
  const [linkedCasesDialogOpen, setLinkedCasesDialogOpen] = useState(false);
  const [groupSnackbar, setGroupSnackbar] = useState({
    open: false,
    severity: 'success',
    message: '',
  });
  const [showAuditCaseStatusPopup, setShowAuditCaseStatusPopup] = useState(false);
  const [auditCaseStatusPopupContent, setAuditCaseStatusPopupContent] = useState(null);
  const [refreshing, setRefreshing] = useState(false);
  const [loadingEditing, setLoadingEditing] = useState(false);
  const [loadingDiscarding, setLoadingDiscarding] = useState(false);
  const [showEditingAlert, setShowEditingAlert] = useState(false);
  const [forceCaseUnlockDialogOpen, setForceCaseUnlockDialogOpen] = useState(false);
  const [stateOptions, setStateOptions] = useState([]);
  const [reportHeader, setReportHeader] = useState([]);
  const [userCanEdit, setUserCanEdit] = useState(null);
  const [acceptedTransfer, setAcceptedTransfer] = useState(false);
  const navigate = useNavigate();
  const [caseTransferInfo, setCaseTransferInfo] = useState([]);
  const [caseAssignmentOpen, setCaseAssignmentsOpen] = useState(false);

  const [
    shouldRefecthCaseDataOnAssignmentManagerClose,
    setShouldRefecthCaseDataOnAssignmentManagerClose,
  ] = useState(false);

  const { VITE_API_URL } = import.meta.env;
  const auth = useAuth();
  const location = useLocation();
  const classes = useStyles();

  useEffect(() => {
    setAcceptedTransfer(
      formik.values?.caseInvestigations?.meAction?.optionSeq ===
        MEAction.Constants.Disposition.Transfer &&
        formik.values?.caseInvestigations.isTransferAccepted
    );
  }, [
    formik.values?.caseInvestigations?.meAction,
    formik.values?.caseInvestigations.isTransferAccepted,
  ]);

  useEffect(() => {
    const canUserEdit = async () => {
      const userHasEditRole = await auth.user.hasRole('EDIT-CASE');

      setUserCanEdit(!acceptedTransfer && userHasEditRole);
    };

    canUserEdit();
  }, [auth.user, acceptedTransfer]);

  const fetchCaseTransferInfo = async () => {
    await fetch(
      VITE_API_URL + 'GetCaseTransferInfo?CaseSeq=' + formik.values?.caseSummary?.caseSeq,
      {
        method: 'GET',
      }
    )
      .then(res => {
        if (res.status == 401) {
          throw new Error('You are unauthorized to use this tool');
        } else if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        if (data.message != null) {
          alert(data.message);
        } else {
          setCaseTransferInfo(data);
        }
      })
      .catch(e => {
        // alert(e);
      });
  };

  const getEditingStatus = async () => {
    await fetch(
      VITE_API_URL + `getcasemodulelocks?lockObjectSeq=${formik.values?.caseSummary?.caseSeq}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.user.token,
        },
      }
    )
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occurred');
        }
        return res.json();
      })
      .then(data => {
        if ((data?.length ?? 0) === 0) {
          formik.setStatus({ ...formik.status, caseLocked: false, editingStatus: null });
        } else if (data?.[0]?.user_seq.toUpperCase() === auth?.user?.userSeq.toUpperCase()) {
          formik.setStatus({ ...formik.status, caseLocked: false, editingStatus: null });
        } else {
          formik.setStatus({
            ...formik.status,
            caseLocked: true,
            editingStatus: `This case is currently being edited by ${data[0].full_user_name} (
          ${new Date(data[0].lock_date_time).toLocaleString()})`,
          });
        }
      })
      .catch(e => {
        console.error(e);
      });
  };

  useEffect(() => {
    const performLock = async () => {
      await lockCaseModules();
    };

    if (formik.status?.connection != null && (location?.state?.isEditing ?? false)) {
      performLock();
    }
  }, [formik.status?.connection]);


  const fetchStateOptions = async () => {
    fetch(VITE_API_URL + 'getstateoptions', {
      method: 'GET',
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setStateOptions(data);
      })
      .catch(e => {
        //alert(e);
      });
  };

  const fetchReportHeader = async () => {
    const qryParams = {
      CountySeq: formik?.values?.caseSummary?.jdxSeq,
    };

    const url = `${VITE_API_URL}getreportheader?${new URLSearchParams(qryParams)}`;

    await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setReportHeader(data);
      })
      .catch(e => {
        alert(e);
      });
  };

  useEffect(() => {
    fetchStateOptions();
    fetchReportHeader();
    fetchCaseTransferInfo();
  }, []);

  const lockCaseModules = async () => {
    // Hash the authToken using SHA-3-256
    const sessionId = CryptoJS.SHA3(auth.user.authToken, { outputLength: 200 }).toString();

    await fetch(VITE_API_URL + 'lockallmodules', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + auth.user.token,
      },
      body: JSON.stringify({
        caseSeq: formik.values?.caseSummary?.caseSeq,
        sessionId: sessionId,
        userSeq: auth.user.userSeq,
        lockedDateTime: dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
      }),
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occurred');
        }

        return res.text();
      })
      .then(data => {
        formik.status?.connection?.invoke(
          'LockCase',
          formik.values?.caseSummary?.caseSeq,
          auth.user.userLastName + ', ' + auth.user.userFirstName,
          new Date()
        );

        formik.setStatus({ ...formik.status, editing: true });
      })
      .catch(e => {
        console.error(e);
      });
  };

  const discardCaseEdits = async () => {
    if (!loadingDiscarding) {
      setLoadingDiscarding(true);

      await fetch(VITE_API_URL + 'deletecasemodulelocks', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.user.token,
        },
        body: JSON.stringify({
          LockObjectSeq: formik.values?.caseSummary?.caseSeq,
        }),
      })
        .then(res => {
          if (res.status >= 400) {
            throw new Error('An error occurred');
          }
          return res.text();
        })
        .then(data => {
          formik.status?.connection?.invoke(
            'DiscardCaseEdits',
            formik.values?.caseSummary?.caseSeq
          );
          // formik.resetForm();
          // Check if location.state.cmscaseid is defined and valid
          if (location.state && location.state.cmscaseid) {
            getCMSCaseData(location.state.cmscaseid); // Fetch the updated data
          } else {
            console.error('cmscaseid is missing in location.state');
          }
          formik.setStatus({
            ...formik.status,
            editing: false,
            caseLocked: false,
            editingStatus: null,
          });
        })
        .catch(e => {
          console.error(e);
        });

      setLoadingDiscarding(false);
    }
  };

  const saveCaseEdits = async () => {
    await fetch(VITE_API_URL + 'deletecasemodulelocks', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + auth.user.token,
      },
      body: JSON.stringify({
        LockObjectSeq: formik.values?.caseSummary?.caseSeq,
      }),
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occurred');
        }
        return res.text();
      })
      .then(data => {
        formik.submitForm();
      })
      .catch(e => {
        console.error(e);
      });
  };

  const AuditCaseStatus = async () => {
    try {
      const response = await fetch(`${VITE_API_URL}AuditCaseStatus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.user.token}`,
        },
        body: JSON.stringify({ caseSeq: formik.values?.caseSummary?.caseSeq }),
      });

      if (response.status === 401) {
        throw new Error('You are unauthorized to use this tool');
      } else if (response.status >= 400) {
        throw new Error('An error occurred');
      }

      const data = await response.text();
      setAuditCaseStatusPopupContent(JSON.stringify(data, null, 2));
      setShowAuditCaseStatusPopup(true);
    } catch (e) {
      console.error(e);
    }
  };

  const closeAuditCaseStatusPopup = () => {
    setShowAuditCaseStatusPopup(false);
    window.location.reload();
  };

  const refreshEditingStatus = () => {
    setRefreshing(true);

    fetch(
      VITE_API_URL + `getcasemodulelocks?lockObjectSeq=${formik.values?.caseSummary?.caseSeq}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.user.token,
        },
      }
    )
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occurred');
        }
        return res.json();
      })
      .then(data => {
        console.log(data);
        if ((data?.length ?? 0) === 0) {
          formik.setStatus({ ...formik.status, caseLocked: false, editingStatus: null });
        } else {
          formik.setStatus({
            ...formik.status,
            caseLocked: true,
            editingStatus: `This case is currently being edited by ${data[0].full_user_name} (
          ${new Date(data[0].lock_date_time).toLocaleString()})`,
          });
        }

        setRefreshing(false);
      })
      .catch(e => {
        console.error(e);
        setRefreshing(false);
      });
  };

  const RefreshButton = () => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {refreshing ? (
          <CircularProgress size='1.5rem' />
        ) : (
          <IconButton onClick={refreshEditingStatus} size='small' sx={{ p: 0, m: 0 }}>
            <Refresh />
          </IconButton>
        )}
      </Box>
    );
  };

  const handleEditClick = async () => {
    if (!loadingEditing && !formik.status?.editing) {
      // This prevents multiple clicks of the edit button, which can lock a user out
      setLoadingEditing(true);

      if (formik.status?.RECORD_LOCKING_ENABLED === true) {
        await getEditingStatus();

        if (!formik.status?.caseLocked) {
          await lockCaseModules();

          if (currentTab === 8) {
            setCurrentTab(0);
          }
        }
      } else {
        formik.setStatus({ ...formik.status, editing: true });

        if (currentTab === 8) {
          setCurrentTab(0);
        }
      }

      setLoadingEditing(false);
    }
  };

  const handleCancelEditClick = async () => {
    if (formik.status?.RECORD_LOCKING_ENABLED === true) {
      await discardCaseEdits();
    } else {
      // formik.resetForm();
      await getCMSCaseData(location.state.cmscaseid); //fetch the updated data
      formik.setStatus({ ...formik.status, editing: false });
    }
  };

  const handleUpdateCaseClick = async () => {
    if (!formik.status?.loadingSaving && formik.status?.editing) {
      formik.setStatus({ ...formik.status, loadingSaving: true });

      if (formik.status?.RECORD_LOCKING_ENABLED === true) {
        await saveCaseEdits();
      } else {
        formik.submitForm();
      }
    }
  };

  const handleTimelineClick = () => {
    setTimelineModal(true);
  };

  const handleMenuClick = () => {
    setMenuOpen(true);
  };

  const handleGroupedChipClick = () => {
    setGroupedCasesDialogOpen(true);
  };

  const handleLinkedChipClick = () => {
    setLinkedCasesDialogOpen(true);
  };

  const handleCloseGroupSnackbar = () => {
    setGroupSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleGroupActionComplete = (status, groupId) => {
    if (status === 'success') {
      setGroupSnackbar({
        open: true,
        severity: 'success',
        message: `Case was successfully grouped to, Group ID: ${groupId}`,
      });
    } else {
      setGroupSnackbar({
        open: true,
        severity: 'error',
        message: 'An error occurred, unable to create new group or add case to group.',
      });
    }
  };

  const handleOpenForceCaseUnlockDialog = () => {
    setForceCaseUnlockDialogOpen(true);
  };

  const caseSummary = formik.values?.caseSummary;
  const caseInvest = formik.values?.caseInvestigations;
  const caseDemo = formik.values?.caseDemographics;
  const caseMort = formik.values?.caseMortuary;
  const caseCert = formik.values?.caseCertification;
  const casePath = formik.values?.casePathology;

  const next_of_kin = caseDemo?.informantList
    ?.filter(informant => informant.nextOfKin == 1)
    .map(informant => ({
      name: `${informant.personLastName}, ${informant.personFirstName}`,
      relationship: informant.relship?.optionName || null,
      phone_number: informant?.informantAddress?.contactItem?.[0]?.contactItemDetails || null,
    }));

  const identification = caseDemo?.identifications
    ?.filter(identif => identif.isAccept == true)
    .map(identif => {
      const kinMatch = caseDemo?.informantList.find(
        informant => informant.informantSeq === `${identif?.identifiedBy?.optionSeq}`
      );

      return {
        name: `${identif?.identifiedBy?.optionName}`,
        modality: identif?.modality?.optionName || null,
        relationship: kinMatch?.relship?.optionName || '',
      };
    });

  const sceneExamined = caseInvest?.discovery?.sceneExaminedBy?.map(scene => ({
    date: scene?.dateTime,
    notified_by: scene?.userSeq?.optionName || null,
  }));

  const checkoutAuthorizor = caseMort?.authorizingRelease
    ? caseDemo?.informantList.find(
        informant => informant?.personSeq === caseMort.authorizingRelease?.toLowerCase()
      )
    : null;

  const authorizorName = checkoutAuthorizor
    ? `${checkoutAuthorizor.personLastName}, ${checkoutAuthorizor.personFirstName}`
    : '';

  const identification_status = caseDemo?.identifications
    ? caseDemo.identifications.some(identification => identification.isAccept)
      ? 'Identified'
      : 'Not Identified'
    : 'Not Identified';

  const isReadyForCheckOut =
    caseDemo?.identifications?.some(identification => identification.isAccept === true) &&
    (caseMort?.isExamCompleted === 'true' || caseMort?.isExamCompleted === true) &&
    caseMort?.isHold !== true;

  const checkoutStatus = caseMort?.funeralHomeSeq
    ? 'Checked out'
    : isReadyForCheckOut
    ? 'Ready for Checkout'
    : caseMort?.checkInDate
    ? 'Checked-in'
    : 'Not checked-in';

  const matchState = stateseq => {
    return stateOptions.find(option => option.optionSeq === stateseq);
  };

  function formatPathologyItems(array) {
    return (
      array
        ?.map(item => {
          const displayName = item?.otherText ?? item?.optionName;
          return item?.categoryName ? `${item.categoryName}-${displayName}` : displayName;
        })
        .join(', ') || ''
    );
  }

  const discoveryAddress = caseInvest?.discovery?.foundOnsetIllnessAddress?.[0]?.addressLine1
    ? [
        caseInvest?.discovery?.foundOnsetIllnessAddress?.[0].addressLine1,
        caseInvest?.discovery?.foundOnsetIllnessAddress?.[0].city,
        matchState(caseInvest?.discovery?.foundOnsetIllnessAddress?.[0].stateSeq?.toUpperCase())
          ?.optionName,
        caseInvest?.discovery?.foundOnsetIllnessAddress?.[0].zip,
      ]
        .filter(Boolean)
        .join(', ')
    : undefined;

  const formattedReportHeader = reportHeader?.[0]?.reportHeaderName
    ? reportHeader?.[0]?.reportHeaderName.replace(/<br\/>/g, '\n')
    : undefined;

  function getInformantName(personSeq) {
    const informant = formik.values?.caseDemographics?.informantList?.find(informant => {
      const informantSeqLower = String(informant.personSeq).toLowerCase();
      const personSeqLower = String(personSeq).toLowerCase();
      // console.log(`Checking informant personSeq: ${informant.personSeq} against ${personSeq}`);
      return informantSeqLower === personSeqLower;
    });
    if (informant) {
      return `${informant.personFirstName} ${informant.personLastName}`;
    }
  }
  const case_summary = {
    case_information: {
      assigned_investigator: getValueOrDefault(caseInvest?.assignedInvestigator?.optionName),
      cause_of_death: getValueOrDefault(casePath?.causeOfDeath),
      check_out_authorized_by: getValueOrDefault(authorizorName),
      check_out_status: checkoutStatus,
      check_out_to: getValueOrDefault(caseMort?.funeralHome?.optionName),
      edrs_case_number: getValueOrDefault(caseCert?.edrsNumber),
      exam_date_and_time: getValueOrDefault(caseMort?.examStartDateTime),
      identification_status: identification_status,
      lab_number: getValueOrDefault(caseCert?.labNumber),
      manner_of_death: getValueOrDefault(casePath?.mannerOfDeath?.optionName),
      condition_of_body: formatPathologyItems(casePath?.conditionOfBody),
      unnatural_death: formatPathologyItems(casePath?.unnaturalDeath),
      circumstances_related_to_death: formatPathologyItems(casePath?.circumstancesRelatedToDeath),
      contributory: getValueOrDefault(casePath?.contributory),
      ME_action: getValueOrDefault(caseInvest?.meAction?.optionName),
      physician: getValueOrDefault(formik.values?.caseMortuary?.assignedUser?.optionName),
      investigation_reason: getValueOrDefault(caseInvest?.investigationReason?.optionName),
      physician_to_whom_case_was_released: getValueOrDefault(
        caseInvest?.approvingPathologist?.optionName
      ),
      pronouncing_date_and_time: getValueOrDefault(caseDemo?.pronouncer?.pronounciationDateTime),
      pronouncing_physician: caseDemo?.pronouncer
        ? `${caseDemo?.pronouncer?.lastName}, ${caseDemo?.pronouncer.firstName}`
        : '',
      // rime_status: getValueOrDefault(caseSummary?.caseInformation?.pathologyStatus),
      // tissue_number: getValueOrDefault(caseSummary?.caseInformation?.tissueNumber),
      type_of_exam: getValueOrDefault(caseMort?.examtype?.optionName),
      physician_on_call_at_time_of_case_intake: undefined,
    },
    report_header: formattedReportHeader,
    report_address: getValueOrDefault(reportHeader?.[0]?.reportAddress),
    case_number: getValueOrDefault(caseSummary?.caseNumber),
    case_seq: getValueOrDefault(caseSummary?.caseSeq),
    case_synopsis: getValueOrDefault(caseInvest?.synopsis),
    decedent_information: {
      decedent_name: caseDemo?.decedentPerson
        ? `${caseDemo?.decedentPerson?.lastName}, ${caseDemo?.decedentPerson?.firstName} ${caseDemo?.decedentPerson?.suffix}`
        : '',
      aka: getValueOrDefault(caseInvest?.decedentAka),
    },
    demographic_information: {
      age: caseDemo?.age
        ? caseDemo.age + ' ' + caseDemo?.ageUnit?.optionName?.toLowerCase()
        : undefined,
      birth_date: caseDemo?.dateOfBirth
        ? dayjs(caseDemo.dateOfBirth).format('MM/DD/YYYY')
        : undefined,
      ethnicity_or_race: getValueOrDefault(caseDemo?.race?.optionName),
      gender: getValueOrDefault(caseDemo?.gender?.optionName),
      marital_status: getValueOrDefault(caseDemo?.maritalStatus?.optionName),
      occupation: getValueOrDefault(caseDemo?.occupation?.optionName),
      private_physician: caseDemo?.privatePhysician
        ? caseDemo.privatePhysician?.lastName && caseDemo.privatePhysician?.firstName
          ? `${caseDemo?.privatePhysician?.lastName}, ${caseDemo?.privatePhysician?.firstName}`
          : undefined
        : undefined,
      ssn: getValueOrDefault(caseDemo?.ssn),
    },
    police_notified: {
      date: caseInvest?.discovery?.policeNotifiedBy?.dateTime,
      notified_by: getInformantName(caseInvest?.discovery?.policeNotifiedBy?.personSeq),
      extra: getValueOrDefault(caseInvest?.discovery?.policeCaseNumber),
    },
    last_date_alive: {
      date: caseInvest?.discovery?.lastKnownToBeAlive?.dateTime,
      notified_by: getInformantName(caseInvest?.discovery?.lastKnownToBeAlive?.personSeq),
    },
    injury: {
      date: caseInvest?.discovery?.foundOnsetIllnessBy?.dateTime,
      notified_by: getInformantName(caseInvest?.discovery?.foundOnsetIllnessBy?.personSeq),
      extra: getValueOrDefault(caseInvest?.discovery?.foundOnsetIllnessByType),
      location: discoveryAddress,
    },
    body_location_information: {
      address: getValueOrDefault(caseInvest?.bodyLocation?.[0]?.addressLine1),
      city: getValueOrDefault(caseInvest?.bodyLocation?.[0]?.city),
      state: getValueOrDefault(
        matchState(caseInvest?.bodyLocation?.[0]?.stateSeq?.toUpperCase())?.optionName
      ),
      zipcode: getValueOrDefault(caseInvest?.bodyLocation?.[0]?.zip),
      apt: getValueOrDefault(caseInvest?.bodyLocation?.[0]?.apartmentNo),
      floor: getValueOrDefault(caseInvest?.bodyLocation?.[0]?.floor),
    },
    jurisdiction: getValueOrDefault(caseSummary?.caseJdx),
    jdxLogo: getValueOrDefault(caseSummary?.jdxLogo),
    name: caseDemo?.decedentPerson
      ? `${caseDemo?.decedentPerson?.lastName}, ${caseDemo?.decedentPerson?.firstName}`
      : '',
    next_of_kin_information: next_of_kin,
    identification_information: identification,
    scene_examined_by: sceneExamined,
    residence: {
      address: getValueOrDefault(caseDemo?.decedentAddress?.[0]?.addressLine1),
      apt_suite: getValueOrDefault(caseDemo?.decedentAddress?.[0]?.apartmentNo),
      city_or_town: getValueOrDefault(caseDemo?.decedentAddress?.[0]?.city),
      // country: getValueOrDefault(caseDemo?.decedentAddress?.[0]?.country),
      floor: getValueOrDefault(caseDemo?.decedentAddress?.[0]?.floor),
      state: getValueOrDefault(
        matchState(caseDemo?.decedentAddress?.[0]?.stateSeq?.toUpperCase())?.optionName
      ),
      zip: getValueOrDefault(caseDemo?.decedentAddress?.[0]?.zip),
    },
    requesting_user: auth?.user?.userName,
    location_where_found_description: caseInvest?.discoveryLocationDescription || '',
  };

  // useEffect(() => {
  //   if (formik.status?.disabledClickCount > 2) {
  //     setShowEditingAlert(true);
  //     formik.setStatus({ ...formik.status, disabledClickCount: 0 });
  //   }
  // }, [formik.status?.disabledClickCount]);

  const isEditTriggered = useRef(false);

  useEffect(() => {
    if (
      !isEditTriggered.current &&
      formik.status?.disabledClickCount > 0 &&
      !formik.status?.editing &&
      !formik.status?.caseLocked &&
      userCanEdit
    ) {
      isEditTriggered.current = true;
      handleEditClick();
      formik.setStatus({ ...formik.status, disabledClickCount: 0 });
      setTimeout(() => {
        isEditTriggered.current = false;
      }, 500);
    }
  }, [formik.status?.disabledClickCount]);

  const onCaseAssignmentManagerClose = async () => {
    setCaseAssignmentsOpen(false);
    if (shouldRefecthCaseDataOnAssignmentManagerClose) {
      await getCMSCaseData(location.state.cmscaseid);
      setShouldRefecthCaseDataOnAssignmentManagerClose(false);
    }
  };

  const jdxSeqList = useMemo(() => {
    return formik.values?.caseSummary?.jdxSeq ? [formik.values?.caseSummary?.jdxSeq] : [];
  }, []);

  useEffect(() => {
    const headerHeight = headerRef.current?.offsetHeight || 0;
    setHeaderHeight(headerHeight);
  }, [headerRef.current?.offsetHeight]);

  useEffect(() => {
    if (formik.status.editing) {
      setShowEditingAlert(true);
    } else {
      setShowEditingAlert(false);
    }
  }, [formik.status.editing]);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        width: '100%',
        position: 'sticky',
        top: 0,
        zIndex: 1001,
      }}
      ref={headerRef}
    >
      <Box sx={{ p: 2, width: '100%' }}>
        <Box
          sx={{
            display: { xs: 'block', md: 'flex' },
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          {/* CaseID and Decedent Name */}
          <Stack>
            <Grid
              container
              spacing={1}
              alignItems='center'
              // sx={{ justifyContent: { xs: 'left', md: 'center' } }}
              justifyContent={{ xs: 'center', md: 'left' }}
              sx={{ pb: 0.5 }}
            >
              <Grid xs='auto'>
                <Typography variant='h5' component='h1'>
                  <b>{formik.values?.caseSummary?.caseNumber}</b>
                </Typography>
              </Grid>
              <Grid xs='auto'>
                <Typography variant='h5' component='h1' sx={{ minWidth: 'max-content' }}>
                  ({formik.values?.caseSummary?.decedentInfo?.decedentName})
                </Typography>
              </Grid>

              <Grid xs='auto'>
                <Stack
                  direction='row'
                  spacing={1}
                  alignItems='center'
                  sx={{ justifyContent: { xs: 'center', md: 'left' } }}
                >
                  {Boolean(formik.values?.caseInvestigations?.isTentative) && (
                    <Chip label='Tentative' color='error' size='small' />
                  )}

                  {formik.values?.caseInvestigations?.autopsyObjection?.objector?.some(
                    objector => objector?.isActive && objector?.personSeq != null
                  ) &&
                    formik.values?.caseInvestigations?.autopsyObjection?.meDetails?.autopsyObjectionResolutionSeq?.toUpperCase() !==
                      ObjectionResolution.Objection_Withdrawn?.toUpperCase() && (
                      <Chip label='OBJ' color='error' variant='outlined' size='small' />
                    )}

                  {formik.values?.caseMortuary?.isHold && (
                    <Chip label='HOLD' color='warning' variant='outlined' size='small' />
                  )}

                  {formik.values?.caseSummary?.caseInformation?.meAction && (
                    <Chip
                      label={formik.values?.caseSummary?.caseInformation?.meAction}
                      variant='outlined'
                      size='small'
                      sx={{
                        color: `${getMeActionChipColor(
                          formik.values?.caseSummary?.caseInformation?.meAction
                        )}`,
                        borderColor: `${getMeActionChipColor(
                          formik.values?.caseSummary?.caseInformation?.meAction
                        )}`,
                      }}
                    />
                  )}
                  {formik.values?.caseSummary?.caseInformation?.mannerOfDeath && (
                    <Chip
                      label={formik.values?.caseSummary?.caseInformation?.mannerOfDeath}
                      variant='outlined'
                      size='small'
                      sx={{
                        color: `${getMODChipColor(
                          formik.values?.caseSummary?.caseInformation?.mannerOfDeath
                        )}`,
                        borderColor: `${getMODChipColor(
                          formik.values?.caseSummary?.caseInformation?.mannerOfDeath
                        )}`,
                      }}
                    />
                  )}
                  {!formik.values?.caseSummary?.isActive && (
                    <Chip
                      label='VOID'
                      variant='outlined'
                      size='small'
                      sx={{
                        color: 'red',
                        borderColor: 'red',
                      }}
                    />
                  )}

                  {/* LINKED CASES HAVE YET TO BE IMPLEMENTED */}
                  {/* {formik.values?.caseSummary?.isLinked && (
                    <Chip
                      label='Linked'
                      variant='outlined'
                      size='small'
                      sx={{
                        color: '#51aa4c',
                        borderColor: '#51aa4c',
                      }}
                      onClick={handleLinkedChipClick}
                    />
                  )} */}

                  {formik.values?.caseSummary?.isGrouped && (
                    <Chip
                      label='Grouped'
                      variant='outlined'
                      size='small'
                      sx={{
                        color: '#926dba',
                        borderColor: '#926dba',
                      }}
                      onClick={handleGroupedChipClick}
                    />
                  )}
                </Stack>
              </Grid>
            </Grid>

            {formik.values?.caseSummary.caseInformation.meNotifiedDateTime && (
              <Typography variant='subtitle1' color='gray'>
                <b>Reported:</b> {formik.values?.caseSummary.caseInformation.meNotifiedDateTime}
              </Typography>
            )}

            {/* Case status is currently commented out until it either finds a better home */}
            {/* or there is more agreement on what makes a case 'closed' */}
            {/* {formik.values?.caseCertification?.caseStatus && (
              <Typography variant='subtitle1' color='gray'>
                Case Status:{' '}
                {formik.values?.caseCertification?.caseStatus == 'O' ? 'Open' : 'Closed'}
                {formik.values?.caseCertification?.signedAndFiledReceivedDate &&
                  ' on ' +
                    new dayjs(formik.values?.caseCertification?.signedAndFiledReceivedDate).format(
                      'MMM DD, YYYY'
                    )}
              </Typography>
            )} */}

            {formik.values?.caseInvestigations &&
              formik.values?.caseInvestigations?.assignedInvestigator &&
              formik.values?.caseInvestigations?.assignedInvestigator?.optionSeq && (
                <Typography variant='subtitle1' color='gray'>
                  MLDI: {formik.values?.caseInvestigations?.assignedInvestigator?.optionName}
                </Typography>
              )}

            {formik.values?.caseMortuary &&
              formik.values?.caseMortuary?.assignedUser &&
              formik.values?.caseMortuary?.assignedUser?.optionSeq && (
                <Typography variant='subtitle1' color='gray'>
                  Pathologist: {formik.values?.caseMortuary?.assignedUser?.optionName}
                </Typography>
              )}

            {formik.values?.caseSummary &&
              formik.values?.caseSummary?.caseInformation?.causeOfDeath && (
                <Typography variant='subtitle1' color='gray'>
                  {formik.values?.caseSummary?.caseInformation?.mannerOfDeath}:{' '}
                  {formik.values?.caseSummary?.caseInformation?.causeOfDeath}
                </Typography>
              )}
          </Stack>

          {/* Case Navigation Buttons */}
          <Stack
            direction='row'
            spacing={1}
            sx={{ ml: 'auto', justifyContent: { xs: 'center', md: 'right' } }}
          >
            <Tooltip title={formik.status.editing ? 'Save Changes' : 'Edit Case'}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 'max-content',
                  width: 'max-content',
                }}
              >
                {(loadingEditing || formik.status?.loadingSaving) && (
                  <CircularProgress
                    sx={{
                      position: 'absolute',
                    }}
                  />
                )}
                {userCanEdit && (
                  <IconButton
                    // className={showEditingAlert ? classes.iconButton : ''}
                    // color={showEditingAlert ? 'primary' : ''}
                    color='primary'
                    size={isMediumScreen ? 'medium' : 'large'}
                    sx={{
                      width: 'max-content',
                      height: 'max-content',
                    }}
                    disabled={
                      (formik.status?.RECORD_LOCKING_ENABLED === true &&
                        formik.status?.caseLocked) ||
                      loadingEditing ||
                      formik.status?.loadingSaving
                    }
                    onClick={formik.status.editing ? handleUpdateCaseClick : handleEditClick}
                  >
                    {formik.status.editing ? (
                      <SaveIcon fontSize={isMediumScreen ? 'medium' : 'large'} />
                    ) : (
                      <EditIcon fontSize={isMediumScreen ? 'medium' : 'large'} />
                    )}
                  </IconButton>
                )}
              </Box>
            </Tooltip>

            <Tooltip title='Discard Changes'>
              {formik.status.editing && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: 'max-content',
                    width: 'max-content',
                  }}
                >
                  {loadingDiscarding && (
                    <CircularProgress
                      sx={{
                        position: 'absolute',
                      }}
                    />
                  )}
                  <IconButton
                    color='warning'
                    size={isMediumScreen ? 'medium' : 'large'}
                    sx={{ width: 'max-content', height: 'max-content' }}
                    disabled={loadingDiscarding}
                    onClick={handleCancelEditClick}
                  >
                    <CloseIcon fontSize={isMediumScreen ? 'medium' : 'large'} />
                  </IconButton>
                </Box>
              )}
            </Tooltip>

            <PrintRIMEButton caseSummary={case_summary} isMediumScreen={isMediumScreen} />

            {/* GROUP-CASE-ACCESS */}
            {auth?.user?.roleCheck(['GCA']) && (
              <Tooltip title='Group to an Incident'>
                <IconButton
                  size={isMediumScreen ? 'medium' : 'large'}
                  sx={{ width: 'max-content', height: 'max-content' }}
                  onClick={() => setGroupDialogOpen(true)}
                >
                  <GroupAddIcon fontSize={isMediumScreen ? 'medium' : 'large'} />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip title='Case Assignments'>
              <IconButton
                size={isMediumScreen ? 'medium' : 'large'}
                sx={{ width: 'max-content', height: 'max-content' }}
                onClick={() => setCaseAssignmentsOpen(true)}
              >
                <AssignmentIndIcon fontSize={isMediumScreen ? 'medium' : 'large'} />
              </IconButton>
            </Tooltip>

            <Tooltip title='View Case Timeline'>
              <IconButton
                size={isMediumScreen ? 'medium' : 'large'}
                sx={{ width: 'max-content', height: 'max-content' }}
                onClick={handleTimelineClick}
              >
                <ViewTimelineIcon fontSize={isMediumScreen ? 'medium' : 'large'} />
              </IconButton>
            </Tooltip>

            <Hidden mdUp>
              <Tooltip title='Show Case Menu'>
                <IconButton
                  size={isMediumScreen ? 'medium' : 'large'}
                  sx={{ width: 'max-content', height: 'max-content' }}
                  onClick={handleMenuClick}
                >
                  <MenuIcon fontSize={isMediumScreen ? 'medium' : 'large'} />
                </IconButton>
              </Tooltip>
            </Hidden>
          </Stack>

          <CaseViewTimeline isOpen={timelineModal} setIsOpen={setTimelineModal} />
        </Box>
        {showAuditCaseStatusPopup && (
          <div style={{ textAlign: 'center' }} className='audit-case-status-popup'>
            <br />
            <pre>{auditCaseStatusPopupContent}</pre>
            <button onClick={closeAuditCaseStatusPopup}>Close</button>
          </div>
        )}

        {formik.status?.RECORD_LOCKING_ENABLED === true &&
          formik.status?.caseLocked &&
          formik.status?.editingStatus !== null && (
            <Stack
              direction='row'
              spacing={2}
              sx={{ mt: 1 }}
              alignItems='center'
              justifyContent='center'
            >
              <Typography sx={{ color: 'red' }}>{formik.status?.editingStatus}</Typography>
              <RefreshButton />
              {auth.user.roleCheck(['451', 'f45']) && (
                <Button
                  onClick={handleOpenForceCaseUnlockDialog}
                  color='warning'
                  variant='outlined'
                >
                  FORCE CASE UNLOCK
                </Button>
              )}
            </Stack>
          )}

        <Slide direction='right' in={showEditingAlert} mountOnEnter unmountOnExit>
          <Alert severity='info' sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            <Typography>You are now editing this case.</Typography>
          </Alert>
        </Slide>

        <Slide direction='right' in={acceptedTransfer} mountOnEnter unmountOnExit>
          <Alert severity='warning' sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            <Typography>
              This case has been transferred to: {caseTransferInfo?.toJurisdiction} {'('}{' '}
              {caseTransferInfo?.toCaseID}
              {')'}
            </Typography>
          </Alert>
        </Slide>

        <CaseViewAssignmentsDialog
          open={caseAssignmentOpen}
          setShouldRefetchCaseDataOnClose={setShouldRefecthCaseDataOnAssignmentManagerClose}
          onClose={onCaseAssignmentManagerClose}
          jdxSeqList={jdxSeqList}
        />

        {groupDialogOpen && (
          <CaseViewGroupCasesDialog
            open={groupDialogOpen}
            setOpen={setGroupDialogOpen}
            caseSeq={formik.values?.caseSummary?.caseSeq}
            caseID={formik.values?.caseSummary?.caseNumber}
            onGroupActionComplete={handleGroupActionComplete}
            isEditing = {true}
          />
        )}

        {groupedCasesDialogOpen && (
          <CaseViewGroupCasesDialog
            open={groupedCasesDialogOpen}
            setOpen={setGroupedCasesDialogOpen}
            caseSeq={formik.values?.caseSummary?.caseSeq}
            caseID={formik.values?.caseSummary?.caseNumber}         
          />
        )}

        <ForceCaseUnlockDialog
          open={forceCaseUnlockDialogOpen}
          setOpen={setForceCaseUnlockDialogOpen}
          discardCaseEdits={discardCaseEdits}
        />

        {/* <CaseViewLinkedCasesDialog
          open={linkedCasesDialogOpen}
          setOpen={setLinkedCasesDialogOpen}
        /> */}
      </Box>

      {/* <Slide
        direction='right'
        in={showEditingAlert}
        mountOnEnter
        unmountOnExit
        sx={{ m: 2, mt: 0 }}
      >
        <Alert severity='info' onClose={() => setShowEditingAlert(false)}>
          <Typography variant='body1'>
            Trying to make edits? Don't forget to click the edit button first.
          </Typography>
        </Alert>
      </Slide> */}

    <Snackbar
        open={groupSnackbar.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ position: 'absolute', top: 5 }}
        autoHideDuration={5000}
        onClose={handleCloseGroupSnackbar}
      >
        <Alert
          onClose={handleCloseGroupSnackbar}
          severity={groupSnackbar.severity}
          variant='filled'
          sx={{ width: '100%' }}
        >
          <Typography variant='body1' fontWeight={500}>
            {groupSnackbar.message}
          </Typography>
        </Alert>
      </Snackbar>

      <Divider sx={{ borderColor: '#555', m: 0, p: 0 }} />

      {showErrorAlert && (
        <div style={{ padding: '10px 20px 0 20px' }}>
          <Alert
            style={{ backgroundColor: 'rgba(255, 165, 0, 0.3)' }}
            isOpen={showErrorAlert}
            onClose={() => setShowErrorAlert(false)}
          >
            {Object.keys(validationErrors)?.map(fieldName => (
              <div key={fieldName} style={{ fontSize: '14px', marginBottom: '10px' }}>
                <span>{fieldName}:</span>
                <ul>
                  {validationErrors[fieldName]?.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </div>
            ))}
          </Alert>
        </div>
      )}
    </Box>
  );
}
