import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import { useIncident } from './IncidentContext';
import { missingPersonsData } from './SampleData';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'Case ID', width: 150 },
  { field: 'name', headerName: 'Name', width: 200 },
  { field: 'lastLocation', headerName: 'Last Known Location', width: 200 },
  { field: 'status', headerName: 'Status', width: 150 },
];

const MissingPersons = () => {
  const { incident } = useIncident();

  return (
    <Card sx={{ m: 2 }}>
      <CardHeader
        title='Missing Persons'
        subheader={`Active Incident: ${
          incident === null ? 'None' : `${incident?.incidentDescription} (${incident?.groupID})`
        }`}
      />
      <Divider sx={{ opacity: 0.8 }} />
      <CardContent>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid rows={missingPersonsData} columns={columns} />
        </div>
      </CardContent>
    </Card>
  );
};

export default MissingPersons;
