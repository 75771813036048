import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import { useIncident } from './IncidentContext';

const DVCSHome = () => {
  const { incident } = useIncident();

  return (
    <Card sx={{ m: 2 }}>
      <CardHeader
        title='DVCS Home'
        subheader={`Active Incident: ${
          incident === null ? 'None' : `${incident?.incidentDescription} (${incident?.groupID})`
        }`}
      />
      <Divider sx={{ opacity: 0.8 }} />

      <CardContent>
        <Typography>Use this system to view and manage all disaster incidents.</Typography>
      </CardContent>
    </Card>
  );
};

export default DVCSHome;
