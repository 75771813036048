import AddIcon from '@mui/icons-material/Add';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Divider,
  Paper,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import dimensions from '../../utils/dimensions';
import { useIncident } from '../../views/DVCS/IncidentContext';
import AppTitle from './AppTitle';
import { CommunicationsButtons } from './CommunicationsButtons';
import NavButton from './NavButton';
import Search from './Search';
import { UserMenuDrawer } from './UserMenuDrawer';

export default function DVCSDesktopAppBar() {
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [notificationMenuOpen, setNotificationMenuOpen] = useState(false);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const location = window.location;
  const { VITE_URL } = import.meta.env;
  const isCaseView = location.pathname?.toLowerCase() === `${VITE_URL}caseview`;
  const { incident, setIncident, incidentOptions } = useIncident();

  const handleOpenUserMenu = () => {
    setUserMenuOpen(true);
  };

  const handleCloseUserMenu = () => {
    setUserMenuOpen(false);
  };

  const handleOpenNotificationMenu = () => {
    setNotificationMenuOpen(true);
  };

  const handleCloseNotificationMenu = () => {
    setNotificationMenuOpen(false);
  };

  useEffect(() => {
    console.log(incidentOptions);
  }, [incidentOptions]);

  const NavButtons = () => {
    return (
      <Stack
        direction='row'
        spacing={0.5}
        alignItems='center'
        sx={{
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
      >
        <Autocomplete
          options={incidentOptions}
          value={incident}
          renderInput={params => <TextField {...params} label='Active Incident' />}
          getOptionLabel={o => o.incidentDescription}
          renderOption={(props, option) => (
            <li {...props}>
              <Stack>
                <Typography>{option.incidentDescription}</Typography>
                <Typography sx={{ opacity: 0.6 }}>{option.groupID}</Typography>
              </Stack>
            </li>
          )}
          onChange={(event, value) => setIncident(value)}
          sx={{ width: 230 }}
        />

        <NavButton
          navigationPageTitle='dvcs/dashboard'
          title='Dashboard'
          startIcon={<DashboardIcon />}
          navButtonActiveColor={theme.palette.mode === 'dark' ? '#000' : '#FFF'}
          mono
          sx={{ pl: 1 }}
        />

        <NavButton
          navigationPageTitle='dvcs/cases'
          title='Cases'
          startIcon={<AssignmentIcon />}
          navButtonActiveColor={theme.palette.mode === 'dark' ? '#000' : '#FFF'}
          mono
        />

        <NavButton
          navigationPageTitle='dvcs/missing-persons'
          title='Missing Persons'
          startIcon={<SearchIcon />}
          navButtonActiveColor={theme.palette.mode === 'dark' ? '#000' : '#FFF'}
          mono
        />

        <NavButton
          navigationPageTitle='dvcs/call-center-interface'
          title='Call Center'
          navButtonActiveColor={theme.palette.mode === 'dark' ? '#000' : '#FFF'}
          startIcon={<AddIcon />}
        />
      </Stack>
    );
  };

  return (
    <Paper
      sx={{
        backgroundColor: theme.palette.mode === 'dark' ? '#AA5030' : '#CC8040',
        // color: 'white',
        position: 'sticky',
        top: 0,
        px: 2,
        alignItems: 'center',
        display: { xs: 'none', lg: 'flex' },
        zIndex: 1005,
        height: dimensions.app_bar_height,
        // borderBottom: '1px solid rgba(112, 112, 112, 0.7)',
      }}
      square
      elevation={theme.palette.mode === 'dark' ? 0 : 4}
    >
      <AppTitle />

      <Divider
        orientation='vertical'
        flexItem
        sx={{
          borderColor: 'inherit',
          mx: 2,
          my: 1,
          display: { xs: 'none', md: 'block' },
        }}
      />

      <NavButtons />

      <Search
        sx={{ maxWidth: '400px', float: 'right', mr: 1 }}
        startAdornment={
          <Tooltip
            title={
              <div>
                <Typography variant='body2'>Use * for partial last name search</Typography>
                <Typography variant='body2'>
                  <u>Example: </u>
                </Typography>
                <Typography variant='body2'>
                  For last name '<b>SquarePants</b>'... both <b>Square*</b> and <b>*Pants</b> would
                  match{' '}
                </Typography>
              </div>
            }
          >
            <SearchIcon />
          </Tooltip>
        }
        includePrevNextArrows
      />

      <Stack direction='row' spacing={1} alignItems='center'>
        <CommunicationsButtons
          handleOpenNotificationMenu={handleOpenNotificationMenu}
          handleCloseNotificationMenu={handleCloseNotificationMenu}
          notificationMenuOpen={notificationMenuOpen}
          userMenuOpen={userMenuOpen}
        />

        <UserMenuDrawer
          handleCloseUserMenu={handleCloseUserMenu}
          handleOpenUserMenu={handleOpenUserMenu}
          open={userMenuOpen}
        />
      </Stack>
    </Paper>
  );
}
