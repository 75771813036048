import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  FormControlLabel,
  Grid,
  Divider,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { MdCheckBoxOutlineBlank, MdOutlineCheckBox } from 'react-icons/md';
import { useAuth } from '../utils/auth/AuthService';
import CondensedDataGrid from './CondensedDataGrid';
import { format } from 'date-fns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { ActionList } from './CaseView/CaseViewDateTimeField.jsx';
import DecedentNameHUD from '../utils/components/DecedentNameHUD';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

interface GroupCasesDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  caseSeq?: string | null;
  caseID?: string;
  isEditing?: boolean;
  isGroupEditing?: boolean;
  incidentGroupSeq?: string | null;
  onGroupActionComplete?: (status: 'success' | 'failure', groupId?: string) => void;
}

interface GroupCaseOption {
  caseSeq: string;
  caseID: string;
  firstName: string;
  lastName: string;
}

interface IncidentGroupOption {
  incidentGroupSeq: string | null;
  incidentGroupId: string;
  incidentDescription: string;
  incidentDateTime: string | null;
  isCaseGrouped: boolean;
}

interface CaseList {
  casenumber: string;
  decedentname: string;
}

const matchCount = 20;

export default function GroupCasesDialog({
  open,
  setOpen,
  caseSeq = null,
  caseID = '',
  incidentGroupSeq = null,
  isEditing = false,
  isGroupEditing = false,
  onGroupActionComplete
}: GroupCasesDialogProps) {
  const [caseOptions, setCaseOptions] = useState<GroupCaseOption[]>([]);
  const [selectedCases, setSelectedCases] = useState<GroupCaseOption[]>([]);
  const [loadingCases, setLoadingCases] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [existingGroupOptions, setExistingGroupOptions] = useState<IncidentGroupOption[]>([]);
  const [existingGroupsLoading, setExistingGroupsLoading] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<CaseList | null>(null);;

  const { user } = useAuth();
  const { VITE_API_URL } = import.meta.env;

  const [selectedIncidentGroup, setSelectedIncidentGroup] = useState<IncidentGroupOption>({
    incidentGroupSeq: null,
    incidentGroupId: '',
    incidentDescription: '',
    incidentDateTime: null,
    isCaseGrouped: false,
  });

  const [casesOnIncidentGroup, setCasesOnIncidentGroup] = useState<any[]>([]);
  const [isGenerateIdChecked, setIsGenerateIdChecked] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [casesOnIncidentGroupLoading, setCasesOnIncidentGroupLoading] = useState(false);

  const handleConfirmDelete = (row: any) => {
    setCasesOnIncidentGroup((prev: any) =>
      prev.map((item: any) =>
        item.caseSeq === row?.caseSeq ? { ...item, isActive: false, isChanged: true } : item
      )
    );
    setShowConfirmation(false);
    setItemToDelete(null);
  };

  const handleCancelDelete = () => {
    setShowConfirmation(false);
  };

  const handleAutocompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: IncidentGroupOption | null
  ): void => {
    if (value) {
      setSelectedIncidentGroup({
        incidentGroupSeq: value.incidentGroupSeq,
        incidentGroupId: value.incidentGroupId,
        incidentDescription: value.incidentDescription || '',
        incidentDateTime: value.incidentDateTime,
        isCaseGrouped: false,
      });
      fetchCasesOnIncidentGroup(value.incidentGroupSeq);
    } else {
      setSelectedIncidentGroup({
        incidentGroupSeq: null,
        incidentGroupId: '',
        incidentDescription: '',
        incidentDateTime: null,
        isCaseGrouped: false,
      });
      setCasesOnIncidentGroup([]);
    }
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedIncidentGroup(prevState => ({
      ...prevState,
      incidentDescription: event.target.value, // Update only the description
    }));
  };

  const fetchCaseOptions = debounce(async (caseIdOrLastName: string) => {
    if (caseIdOrLastName.includes('--') || caseIdOrLastName.trim() === '') {
      setCaseOptions([]);
      return;
    }

    const caseIdRegex =
      /^(\d{1,2})(?:(?<=\d{2})(-\d{0,2}){0,1})?(?:(?<=\d{2}-\d{2})(-\d{0,4}){0,1})?$/;

    let caseID: string | null = null;
    let lastName: string | null = null;

    if (caseIdRegex.test(caseIdOrLastName)) {
      caseID = caseIdOrLastName;
    } else {
      lastName = caseIdOrLastName;
    }

    setLoadingCases(true);

    try {
      const response = await fetch(
        `${VITE_API_URL}GetCaseMatchesFromCaseIdOrLastName?count=${matchCount}&caseID=${
          caseID ?? ''
        }&lastName=${lastName ?? ''}`,
        {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + user?.token,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch case options.');
      }

      const data: GroupCaseOption[] = await response.json();
      // Every option except ourselves
      const filteredData = data.filter(
        option => option.caseSeq?.toUpperCase() !== caseSeq?.toUpperCase()
      );
      setCaseOptions(filteredData);
    } catch (error) {
      console.error(error);
      setCaseOptions([]);
    } finally {
      setLoadingCases(false);
    }
  }, 300); // 300ms debounce time

  const handleSubmit = async () => {
    setLoadingSubmit(true);

    try {
      let groupCases = [];
      if (isGroupEditing) {
        groupCases = [
          ...new Set([
            ...selectedCases.map(caseOpt => ({ caseSeq: caseOpt.caseSeq, isActive: true, isChanged: true })),
            ...casesOnIncidentGroup.map(item => ({ caseSeq: item.caseSeq, isActive: item.isActive, isChanged: (item?.isChanged || false) })),
          ]),
        ].filter(item => item.isChanged);
      } else {
        groupCases.push({ caseSeq, isActive: true, isChanged: true }); // Current case with active status
      }

      const payload = {
        incidentGroupDetails: {
          IncidentGroupSeq: selectedIncidentGroup?.incidentGroupSeq,
          IncidentGroupId: selectedIncidentGroup?.incidentGroupId,
          IncidentDescription: selectedIncidentGroup?.incidentDescription,
          IncidentDateTime: selectedIncidentGroup?.incidentDateTime || new Date().toISOString(), 
        },
        userSeq: user?.userSeq,
        groupCases: groupCases,
      };

      const response = await fetch(VITE_API_URL + 'createIncidentGroup', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + user?.token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to create incident group.');
      }

      const result = await response.json();
      console.log(result); // Log the server response for debugging   
      onGroupActionComplete?.('success', result.incidentGroupId);
      setOpen(false);
    } catch (error) {
      console.error(error);
      onGroupActionComplete?.('failure');
      // alert(error.message || 'An error occurred.');
    } finally {
      setLoadingSubmit(false);
    }
  };

  const fetchExistingGroupOptions = async () => {
    setExistingGroupsLoading(true);
    fetch(VITE_API_URL + 'getexistingincidentgroupoptions', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user?.token,
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setExistingGroupOptions(data);
        if (incidentGroupSeq) {
          const matchedGroup = data.find((group : any) => group.incidentGroupSeq === incidentGroupSeq);
          if (matchedGroup) {
            setSelectedIncidentGroup(matchedGroup);
          }
        }
      })
      .catch(e => {
        alert(e);
      })
      .finally(() => {
        setExistingGroupsLoading(false);
      });
  };

  const fetchCaseOnIncidentGroup = async () => {
    if (!caseSeq) return;

    fetch(VITE_API_URL + 'getcaseonincidentgroup?CaseSeq=' + caseSeq, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user?.token,
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setSelectedIncidentGroup(data);
        if (data?.incidentGroupSeq) {
          fetchCasesOnIncidentGroup(data.incidentGroupSeq);
        }
      })
      .catch(e => {
        alert(e);
      });
  };

  const fetchCasesOnIncidentGroup = async (incidentGroupSeq: string | null) => {
    if (!incidentGroupSeq) return;

    setCasesOnIncidentGroupLoading(true);
    fetch(VITE_API_URL + 'getcasesonincidentgroup?IncidentGroupSeq=' + incidentGroupSeq, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + user?.token,
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        if (res.status >= 400) {
          throw new Error('An error occured');
        }
        return res.json();
      })
      .then(data => {
        setCasesOnIncidentGroup(data);
      })
      .catch(e => {
        alert(e);
      })
      .finally(() => {
        setCasesOnIncidentGroupLoading(false);
      });
  };

  useEffect(() => {
    if (open) {
      fetchExistingGroupOptions();
      if (incidentGroupSeq) {
        fetchCasesOnIncidentGroup(incidentGroupSeq);
      } else {
        fetchCaseOnIncidentGroup();
      }
    }
  }, []);

  useEffect(() => {
    const isGroupIdValid = Boolean(selectedIncidentGroup?.incidentGroupId || isGenerateIdChecked);
    const isDescriptionValid = Boolean(selectedIncidentGroup?.incidentDescription);

    setIsFormValid(isGroupIdValid && isDescriptionValid);
  }, [
    selectedIncidentGroup?.incidentGroupId,
    selectedIncidentGroup?.incidentDescription,
    isGenerateIdChecked,
  ]);

  const columns = [
    {
      field: 'casenumber',
      headerName: 'CaseID',
      width: 150,
      flex: 1,
    },
    {
      field: 'menotified',
      headerName: 'Reported',
      width: 150,
      flex: 1,
      valueFormatter: (value: any) => {
        return value ? format(new Date(value), 'MM/dd/yyyy, HH:mm') : '';
      },
    },
    {
      field: 'decedentname',
      headerName: 'Decedent',
      width: 150,
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      width: 80,
      renderCell: (params : any) => {
        const onDeleteClick = (e: React.MouseEvent, row: any) => {
          e.stopPropagation();
          // setShowConfirmation(true);
          // setItemToDelete(row);
          handleConfirmDelete(row)
        };
        const jdxCode = params.row.casenumber.split('-')[0]; 
        const hasJdxAccess = user?.jdxAccessList.some((jdx) => jdx.jdxCode === jdxCode);

        return (
          <div>
            {/* System-Admin and jurisdiction access user can remove the case from the group. (need to check on systems admin how this should work)*/}
            {/* (hasJdxAccess || user?.roleCheck(['451'])) */}
          {hasJdxAccess && (
            <IconButton
              onClick={e => onDeleteClick(e, params.row)}
              style={{
                justifyContent: 'left',
              }}
              size='small'
              color='error'
            >
              <DeleteIcon fontSize='small'/>
            </IconButton>
          )}
          </div>
        );
      },
    },
  ];

  const ConfirmationModal = () => {
    return (
      <Dialog
        open={showConfirmation}
        onClose={handleCancelDelete}
        aria-labelledby='responsive-dialog-title'
      >
        <DialogTitle id='responsive-dialog-title'>{'Confirm Void File'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to remove this case from group?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDelete} autoFocus>
            Yes
          </Button>
          <Button autoFocus onClick={handleCancelDelete}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };


  return (
    <>
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='md'>
      <DialogTitle>
        <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
          <strong>Incident Group</strong>
        </Typography>
      </DialogTitle>
      <DialogContent
        dividers
        // style={{
        //   pointerEvents: isEditing ? 'auto' : 'none',
        // }}
      >
        <Grid container spacing={2}>
          {isEditing && !isGroupEditing && (
            <Grid item xs={12}>
              <DecedentNameHUD />
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <Autocomplete
              loading={existingGroupsLoading}
              options={existingGroupOptions}
              value={
                isGenerateIdChecked
                  ? null
                  : existingGroupOptions.find(
                      option => option.incidentGroupId === selectedIncidentGroup.incidentGroupId
                    ) || null
              }
              getOptionLabel={option => option.incidentGroupId}
              onChange={handleAutocompleteChange}
              disabled={!isEditing || isGenerateIdChecked || isGroupEditing}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Group ID'
                  placeholder='Select or generate a incident group id'
                  variant='outlined'
                  fullWidth
                  required
                />
              )}
            />
          </Grid>

          {isEditing && (
            <Grid item xs={12} md={6}>
              <FormControlLabel
                label='Generate a new Group ID'
                control={
                  <Checkbox
                    checked={isGenerateIdChecked}
                    onChange={event => {
                      const isChecked = event.target.checked;
                      setIsGenerateIdChecked(isChecked);
                      if (isChecked) {
                        setSelectedIncidentGroup({
                          incidentGroupSeq: null,
                          incidentGroupId: '',
                          incidentDescription: '',
                          incidentDateTime: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
                          isCaseGrouped: false,
                        });
                        setCasesOnIncidentGroup([]);
                      }
                    }}
                  />
                }
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              disabled={!isGroupEditing && !isGenerateIdChecked}
              id='incidentDescription'
              name='incidentDescription'
              label='Description of the Incident'
              variant='outlined'
              fullWidth
              type='text'
              value={selectedIncidentGroup.incidentDescription || ''} // Display group description
              onChange={handleDescriptionChange}
              required
              multiline
              inputProps={{
                maxLength: 1000,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                name='incidentDateTime'
                label='Date of the Incident'
                disabled={!isGroupEditing && !isGenerateIdChecked}
                value={
                  selectedIncidentGroup.incidentDateTime
                    ? dayjs(selectedIncidentGroup.incidentDateTime)
                    : null
                }
                onChange={date => {
                  const localDate = date?.isValid()
                    ? dayjs(date).format('YYYY-MM-DDTHH:mm:ss')
                    : null;
                  setSelectedIncidentGroup(prevState => ({
                    ...prevState,
                    incidentDateTime: localDate,
                  }));
                }}
                // disableFuture={true}
                timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
                ampm={false}
                slots={{
                  actionBar: ActionList,
                }}
                slotProps={{
                  field: { clearable: true },
                  textField: { id: 'incidentDateTime' },
                }}
                sx={{
                  minWidth: '100%',
                }}
              />
            </LocalizationProvider>
          </Grid>

          {isGroupEditing && (
            <>
          <Grid item xs={12}>
          <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
          <strong>Search for additional cases to be included in the Group:</strong>
          </Typography>
          </Grid>
          <Grid item xs={12}>
          <Autocomplete
            disableCloseOnSelect
            multiple
            // options={caseOptions}
            options={caseOptions.filter(option => {
              const jdxCode = option.caseID.split('-')[0];
              return user?.jdxAccessList.some(jdx => jdx.jdxCode === jdxCode);
            })}
            value={selectedCases}
            onChange={(event, newValue) => setSelectedCases(newValue)}
            isOptionEqualToValue={(option, value) => option.caseSeq === value.caseSeq}
            getOptionLabel={option => `${option.caseID} -- ${option.lastName}, ${option.firstName}`}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<MdCheckBoxOutlineBlank />}
                  checkedIcon={<MdOutlineCheckBox />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.caseID} -- {option.lastName}, {option.firstName}
              </li>
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  label={`${option.caseID} -- ${option.lastName}, ${option.firstName}`}
                  key={option.caseSeq}
                />
              ))
            }
            renderInput={params => (
              <TextField
                {...params}
                label='Case ID or Last Name'
                variant='outlined'
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingCases && <CircularProgress color='inherit' size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
                onChange={event => fetchCaseOptions(event.target.value)}
              />
            )}
            loading={loadingCases}
            fullWidth
          /> 
          </Grid>
          </>
          )}

          <Grid item xs={12}>
            <Divider sx={{ borderColor: '#555', m: 0, pb: 0 }} />
            <Typography variant='subtitle1' color='gray' textTransform='uppercase'>
              <strong>{isEditing ? 'Cases already in this group:' : 'Cases in this group:'}</strong>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <CondensedDataGrid
              loading={casesOnIncidentGroupLoading}
              listresults= {casesOnIncidentGroup.filter(item => item.isActive) || []}
              columnsInput={columns}
              idcolumn='casenumber'
              gridcolor='#0288d1'
              gridheight={casesOnIncidentGroup?.length > 0 ? 'auto' : '100'}
              rowsperpage={10}
              tooltip='List of Grouped cases'
              columnsVisibility={{
                actions: isGroupEditing,
              }}
            />
          </Grid>
        </Grid>     
      </DialogContent>

      <DialogActions style={{ justifyContent: 'right', marginRight: '1rem', marginBottom: '1rem' }}>
        {(isEditing || isGroupEditing) && (
          <LoadingButton
            onClick={handleSubmit}
            loading={loadingSubmit}
            variant='contained'
            color='primary'
            disabled={!isFormValid}
          >
            {isGroupEditing?  'Save Group'
              : isGenerateIdChecked
              ? `Create and add this case (${caseID}) to the Group`
              : selectedIncidentGroup.isCaseGrouped
              ? `Update this case Group`
              : `Add this case (${caseID}) to the Group`}
          </LoadingButton>
        )}

        <Button
          onClick={() => {
            setOpen(false);
            setCasesOnIncidentGroup([]);
          }}
          color='secondary'
        >
          { (isEditing || isGroupEditing) ? 'Cancel' : 'Close' }
        </Button>
      </DialogActions>
    </Dialog>

    <ConfirmationModal />
    </>
  );
}
