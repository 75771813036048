// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
// import { InputAdornment } from '@mui/material';
// import Autocomplete from '@mui/material/Autocomplete';
// import Box from '@mui/material/Box';
// import Collapse from '@mui/material/Collapse';
// import Grid from '@mui/material/Grid';
// import IconButton from '@mui/material/IconButton';
// import TextField from '@mui/material/TextField';
// import Typography from '@mui/material/Typography';
// import { useFormikContext } from 'formik';
// import { useEffect, useState } from 'react';
// import { Transition } from 'react-transition-group';

// // Use the aws sdk
// import AWS from 'aws-sdk';

// // Set the region where your identity pool exists (us-east-1, eu-west-1)
// AWS.config.region = 'us-east-1';

// // Configure the credentials provider to use your identity pool
// AWS.config.credentials = new AWS.CognitoIdentityCredentials({
//   IdentityPoolId: 'us-east-1:269924ac-7862-42d3-9a9c-4bd1c1861f39',
// });

// // Make the call to obtain credentials
// await AWS.config.credentials.get();

// // Make the location options
// const locationOptions = {
//   region: AWS.config.region,
//   credentials: AWS.config.credentials,
// };

// // Create the location client
// const location = new AWS.Location(locationOptions);

// export default function AddressField({ placeholder, fieldName }) {
//   const formik = useFormikContext();
//   const { value: fieldValue } = formik.getFieldProps(fieldName);
//   const [expanded, setExpanded] = useState(false);
//   const [autocompleteOptions, setAutocompleteOptions] = useState([]);
//   const [deviceCoords, setDeviceCoords] = useState(null);
//   const [stateOptions, setStateOptions] = useState([]);
//   const [countryOptions, setCountryOptions] = useState([]);
//   const [addressInput, setAddressInput] = useState('');
//   const { VITE_API_URL } = import.meta.env;

//   const options = {
//     enableHighAccuracy: true,
//     timeout: 5000,
//     maximumAge: 0,
//   };

//   const success = pos => {
//     setDeviceCoords(pos.coords);
//   };

//   const errors = err => {
//     console.warn(`ERROR(${err.code}): ${err.message}`);
//   };

//   const getState = stateName => {
//     for (var state of stateOptions) {
//       if (state.optionName == stateName) {
//         return state;
//       }
//     }

//     return null;
//   };

//   const getCountry = countryName => {
//     if (countryName == 'USA') {
//       countryName = 'United States';
//     }

//     for (var country of countryOptions) {
//       if (country.optionName == countryName) {
//         return country;
//       }
//     }

//     return null;
//   };

//   const handleExpandAddress = () => {
//     setExpanded(!expanded);
//   };

//   const handleAddressInputChange = async (event, data) => {
//     if (data == '') {
//       setExpanded(false);
//     }

//     setAddressInput(data);

//     const searchForSuggestionsParams = {
//       IndexName: 'MedexLabPlaceIndex',
//       Text: data,
//       BiasPosition: deviceCoords
//         ? [deviceCoords.longitude, deviceCoords.latitude]
//         : [-74.450507, 40.181042],
//     };

//     if (data.length > 0) {
//       await location.searchPlaceIndexForSuggestions(
//         searchForSuggestionsParams,
//         function (err, data) {
//           setAutocompleteOptions(data.Results);
//         }
//       );
//     }
//   };

//   const handleAddressTextChange = async (event, data) => {
//     if (data != null && data.Text.length > 0) {
//       const getPlaceParams = {
//         IndexName: 'MedexLabPlaceIndex',
//         PlaceId: data.PlaceId,
//       };

//       await location.getPlace(getPlaceParams, function (err, results) {
//         const placeData = results.Place;

//         formik.setFieldValue(fieldName, {
//           addressText: data,
//           data: {
//             addressLine1:
//               (placeData?.AddressNumber ?? '') +
//               (placeData?.AddressNumber != null ? ' ' : '') +
//               placeData?.Street.replace(/\s+/g, ' '),
//             addressLine2:
//               placeData?.UnitType != null && placeData?.UnitType !== 'Apt'
//                 ? (placeData?.UnitType ?? '') +
//                   (placeData?.UnitType != null ? ' ' : '') +
//                   (placeData?.UnitNumber ?? '')
//                 : null,
//             aptSuite: placeData?.UnitType === 'Apt' ? placeData?.UnitNumber : null,
//             cityTown: placeData?.Municipality,
//             state: getState(placeData?.Region),
//             zipCode: placeData?.PostalCode,
//             country: getCountry(placeData?.Country),
//           },
//         });

//         setExpanded(true);
//       });
//     } else {
//       formik.setFieldValue(fieldName, {
//         ...fieldValue,
//         addressText: null,
//         data: {
//           addressLine1: null,
//           addressLine2: null,
//           address: null,
//           aptSuite: null,
//           floor: null,
//           cityTown: null,
//           state: null,
//           zipCode: null,
//           country: null,
//         },
//       });

//       setExpanded(false);
//     }
//   };

//   const handleChange = (field, value) => {
//     formik.setFieldValue(fieldName, {
//       ...fieldValue,
//       data: {
//         ...fieldValue.data,
//         [field]: value,
//       },
//     });
//   };

//   useEffect(() => {
//     // INITIALIZE STATE OPTIONS
//     fetch(VITE_API_URL + 'getstateoptions', {
//       method: 'GET',
//     })
//       .then(res => {
//         return res.json();
//       })
//       .then(data => {
//         setStateOptions(data);
//       })
//       .catch(e => {
//         //alert(e);
//       });

//     // INITIALIZE COUNTRY OPTIONS
//     fetch(VITE_API_URL + 'getcountryoptions', {
//       method: 'GET',
//     })
//       .then(res => {
//         return res.json();
//       })
//       .then(data => {
//         setCountryOptions(data);
//       })
//       .catch(e => {
//         //alert(e);
//       });

//     if (navigator.geolocation) {
//       navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
//         if (result.state === 'granted') {
//           navigator.geolocation.getCurrentPosition(success);
//         } else if (result.state === 'prompt') {
//           navigator.geolocation.getCurrentPosition(success, errors, options);
//         } else if (result.state === 'denied') {
//           // Show instructions to enable location
//         }
//       });
//     }
//   }, []);

//   return (
//     <Box>
//       <Transition in={expanded} timeout={300}>
//         {state => (
//           <Box
//             sx={{
//               transition: 'padding 300ms ease-in-out',
//               pb: expanded ? 2 : 0,
//               display: 'flex',
//             }}
//           >
//             <Autocomplete
//               options={autocompleteOptions}
//               value={fieldValue.addressText}
//               size='small'
//               getOptionLabel={option => option.Text}
//               onChange={handleAddressTextChange}
//               // sx={{ pr: 1 }}
//               renderOption={(props, option, { selected }) => (
//                 <li {...props}>
//                   <Grid container alignItems='center'>
//                     <Grid item>
//                       <Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
//                     </Grid>
//                     <Grid item xs>
//                       <span
//                         style={{
//                           fontWeight:
//                             option.Text.substring(0, addressInput.length) === addressInput
//                               ? 700
//                               : 400,
//                         }}
//                       >
//                         {option.Text.substring(0, addressInput.length) === addressInput
//                           ? option.Text.substring(0, addressInput.length)
//                           : option.Text.split(',')[0]}
//                       </span>

//                       <Typography variant='body2' color='text.secondary'>
//                         {option.Text}
//                       </Typography>
//                     </Grid>
//                   </Grid>
//                 </li>
//               )}
//               popupIcon={null}
//               fullWidth
//               onInputChange={handleAddressInputChange}
//               renderInput={params => (
//                 <TextField
//                   {...params}
//                   label={placeholder}
//                   placeholder={placeholder}
//                   sx={{
//                     '& .MuiOutlinedInput-root': {
//                       paddingRight: '0px !important',
//                     },
//                   }}
//                   InputProps={{
//                     ...params.InputProps,
//                     endAdornment: (
//                       <>
//                         {params.InputProps.endAdornment}
//                         <InputAdornment position='end'>
//                           <IconButton onClick={handleExpandAddress}>
//                             <Transition in={expanded} timeout={300}>
//                               {state => (
//                                 <ChevronLeftIcon
//                                   sx={{
//                                     transition: 'transform 300ms ease-in-out',
//                                     transform: expanded ? 'rotate(-90deg)' : 'rotate(0deg)',
//                                   }}
//                                 />
//                               )}
//                             </Transition>
//                           </IconButton>
//                         </InputAdornment>
//                       </>
//                     ),
//                   }}
//                 />
//               )}
//               filterOptions={x => x}
//             />
//           </Box>
//         )}
//       </Transition>
//       <Collapse in={expanded} sx={{ pt: 0 }}>
//         <Grid container spacing={2}>
//           <Grid item xs={12} md={6}>
//             <TextField
//               value={fieldValue.data.addressLine1 ?? ''}
//               onChange={event => handleChange('addressLine1', event.target.value)}
//               size='small'
//               fullWidth
//               variant='outlined'
//               label='Address Line 1'
//               type='text'
//               InputLabelProps={{
//                 shrink: fieldValue.data.addressLine1 !== null,
//               }}
//             />
//           </Grid>

//           <Grid item xs={12} md={6}>
//             <TextField
//               value={fieldValue.data.addressLine2 ?? ''}
//               onChange={event => handleChange('addressLine2', event.target.value)}
//               size='small'
//               fullWidth
//               variant='outlined'
//               id='dob'
//               label='Address Line 2'
//               type='text'
//               InputLabelProps={{
//                 shrink: fieldValue.data.addressLine2 !== null,
//               }}
//             />
//           </Grid>

//           <Grid item xs={12} md={4}>
//             <TextField
//               value={fieldValue.data.aptSuite ?? ''}
//               onChange={event => handleChange('aptSuite', event.target.value)}
//               size='small'
//               fullWidth
//               variant='outlined'
//               id='dob'
//               label='Apartment #'
//               type='text'
//               InputLabelProps={{
//                 shrink: fieldValue.data.aptSuite !== null,
//               }}
//             />
//           </Grid>

//           <Grid item xs={12} md={4}>
//             <TextField
//               value={fieldValue.data.floor ?? ''}
//               onChange={event => handleChange('floor', event.target.value)}
//               size='small'
//               fullWidth
//               variant='outlined'
//               id='dob'
//               label='Floor #'
//               type='text'
//               InputLabelProps={{
//                 shrink: fieldValue.data.floor !== null,
//               }}
//             />
//           </Grid>

//           <Grid item xs={12} md={4}>
//             <TextField
//               value={fieldValue.data.cityTown ?? ''}
//               onChange={event => handleChange('cityTown', event.target.value)}
//               size='small'
//               fullWidth
//               variant='outlined'
//               id='dob'
//               label='City/Town'
//               type='text'
//               InputLabelProps={{
//                 shrink: fieldValue.data.cityTown !== null,
//               }}
//             />
//           </Grid>

//           <Grid item xs={12} md={4}>
//             <Autocomplete
//               options={stateOptions}
//               value={fieldValue.data.state}
//               onChange={(event, data) => handleChange('state', data)}
//               size='small'
//               isOptionEqualToValue={(option, value) =>
//                 option.optionSeq.toUpperCase() === value.optionSeq.toUpperCase()
//               }
//               fullWidth
//               getOptionLabel={option => option.optionName}
//               renderInput={params => <TextField {...params} label='State' />}
//             />
//           </Grid>

//           <Grid item xs={12} md={4}>
//             <TextField
//               value={fieldValue.data.zipCode ?? ''}
//               onChange={event => handleChange('zipCode', event.target.value)}
//               size='small'
//               fullWidth
//               variant='outlined'
//               id='dob'
//               label='Zip Code'
//               type='text'
//               InputLabelProps={{
//                 shrink: fieldValue.data.zipCode !== null,
//               }}
//             />
//           </Grid>

//           <Grid item xs={12} md={4}>
//             <Autocomplete
//               options={countryOptions}
//               value={fieldValue.data.country}
//               onChange={(event, data) => handleChange('country', data)}
//               size='small'
//               isOptionEqualToValue={(option, value) =>
//                 option.optionSeq.toUpperCase() === value.optionSeq.toUpperCase()
//               }
//               fullWidth
//               getOptionLabel={option => option.optionName}
//               renderInput={params => <TextField {...params} label='Country' />}
//             />
//           </Grid>
//         </Grid>
//       </Collapse>
//     </Box>
//   );
// }

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { InputAdornment } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';

// AWS SDK v3 imports
import {
  LocationClient,
  SearchPlaceIndexForSuggestionsCommand,
  GetPlaceCommand,
} from '@aws-sdk/client-location';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';

// Set AWS region and identity pool ID
const region = 'us-east-1';
const identityPoolId = 'us-east-1:269924ac-7862-42d3-9a9c-4bd1c1861f39';

// Create the Cognito identity client
const cognitoIdentityClient = new CognitoIdentityClient({ region });

// Create credential provider
const credentials = fromCognitoIdentityPool({
  client: cognitoIdentityClient,
  identityPoolId,
});

// Create the location client
const locationClient = new LocationClient({ region, credentials });

export default function AddressField({ placeholder, fieldName }) {
  const formik = useFormikContext();
  const { value: fieldValue } = formik.getFieldProps(fieldName);
  const [expanded, setExpanded] = useState(false);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [deviceCoords, setDeviceCoords] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [addressInput, setAddressInput] = useState('');
  const { VITE_API_URL } = import.meta.env;

  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  const success = pos => {
    setDeviceCoords(pos.coords);
  };

  const errors = err => {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  };

  const getState = stateName => {
    for (let state of stateOptions) {
      if (state.optionName == stateName) {
        return state;
      }
    }
    return null;
  };

  const getCountry = countryName => {
    if (countryName == 'USA') {
      countryName = 'United States';
    }

    for (let country of countryOptions) {
      if (country.optionName == countryName) {
        return country;
      }
    }

    return null;
  };

  const handleExpandAddress = () => {
    setExpanded(!expanded);
  };

  const handleAddressInputChange = async (event, data) => {
    if (data === '') {
      setExpanded(false);
    }

    setAddressInput(data);

    if (data.length > 0) {
      const searchForSuggestionsParams = {
        IndexName: 'MedexLabPlaceIndex',
        Text: data,
        BiasPosition: deviceCoords
          ? [deviceCoords.longitude, deviceCoords.latitude]
          : [-74.450507, 40.181042],
      };

      try {
        const command = new SearchPlaceIndexForSuggestionsCommand(searchForSuggestionsParams);
        const res = await locationClient.send(command);
        setAutocompleteOptions(res.Results);
      } catch (err) {
        console.error('Error fetching suggestions:', err);
      }
    }
  };

  const handleAddressTextChange = async (event, data) => {
    if (data != null && data.Text.length > 0) {
      const getPlaceParams = {
        IndexName: 'MedexLabPlaceIndex',
        PlaceId: data.PlaceId,
      };

      try {
        const results = await locationClient.send(new GetPlaceCommand(getPlaceParams));
        const placeData = results.Place;

        formik.setFieldValue(fieldName, {
          addressText: data,
          data: {
            addressLine1:
              (placeData?.AddressNumber ?? '') +
              (placeData?.AddressNumber != null ? ' ' : '') +
              placeData?.Street.replace(/\s+/g, ' '),
            addressLine2:
              placeData?.UnitType != null && placeData?.UnitType !== 'Apt'
                ? (placeData?.UnitType ?? '') +
                  (placeData?.UnitType != null ? ' ' : '') +
                  (placeData?.UnitNumber ?? '')
                : null,
            aptSuite: placeData?.UnitType === 'Apt' ? placeData?.UnitNumber : null,
            cityTown: placeData?.Municipality,
            state: getState(placeData?.Region),
            zipCode: placeData?.PostalCode,
            country: getCountry(placeData?.Country),
          },
        });

        setExpanded(true);
      } catch (err) {
        console.error('Error fetching place:', err);
      }
    } else {
      formik.setFieldValue(fieldName, {
        ...fieldValue,
        addressText: null,
        data: {
          addressLine1: null,
          addressLine2: null,
          address: null,
          aptSuite: null,
          floor: null,
          cityTown: null,
          state: null,
          zipCode: null,
          country: null,
        },
      });

      setExpanded(false);
    }
  };

  const handleChange = (field, value) => {
    formik.setFieldValue(fieldName, {
      ...fieldValue,
      data: {
        ...fieldValue.data,
        [field]: value,
      },
    });
  };

  useEffect(() => {
    // INITIALIZE STATE OPTIONS
    fetch(VITE_API_URL + 'getstateoptions', {
      method: 'GET',
    })
      .then(res => res.json())
      .then(data => {
        setStateOptions(data);
      })
      .catch(e => {
        console.error(e);
      });

    // INITIALIZE COUNTRY OPTIONS
    fetch(VITE_API_URL + 'getcountryoptions', {
      method: 'GET',
    })
      .then(res => res.json())
      .then(data => {
        setCountryOptions(data);
      })
      .catch(e => {
        console.error(e);
      });

    if (navigator.geolocation) {
      navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
        if (result.state === 'granted') {
          navigator.geolocation.getCurrentPosition(success);
        } else if (result.state === 'prompt') {
          navigator.geolocation.getCurrentPosition(success, errors, options);
        } else if (result.state === 'denied') {
          // Show instructions to enable location
        }
      });
    }
  }, [VITE_API_URL]);

  return (
    <Box>
      <Transition in={expanded} timeout={300}>
        {state => (
          <Box
            sx={{
              transition: 'padding 300ms ease-in-out',
              pb: expanded ? 2 : 0,
              display: 'flex',
            }}
          >
            <Autocomplete
              options={autocompleteOptions}
              value={fieldValue.addressText}
              size='small'
              getOptionLabel={option => option.Text}
              onChange={handleAddressTextChange}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Grid container alignItems='center'>
                    <Grid item>
                      <Box component={LocationOnIcon} sx={{ color: 'text.secondary', mr: 2 }} />
                    </Grid>
                    <Grid item xs>
                      <span
                        style={{
                          fontWeight:
                            option.Text.substring(0, addressInput.length) === addressInput
                              ? 700
                              : 400,
                        }}
                      >
                        {option.Text.substring(0, addressInput.length) === addressInput
                          ? option.Text.substring(0, addressInput.length)
                          : option.Text.split(',')[0]}
                      </span>

                      <Typography variant='body2' color='text.secondary'>
                        {option.Text}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              )}
              popupIcon={null}
              fullWidth
              onInputChange={handleAddressInputChange}
              renderInput={params => (
                <TextField
                  {...params}
                  label={placeholder}
                  placeholder={placeholder}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      paddingRight: '0px !important',
                    },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {params.InputProps.endAdornment}
                        <InputAdornment position='end'>
                          <IconButton onClick={handleExpandAddress}>
                            <Transition in={expanded} timeout={300}>
                              {state => (
                                <ChevronLeftIcon
                                  sx={{
                                    transition: 'transform 300ms ease-in-out',
                                    transform: expanded ? 'rotate(-90deg)' : 'rotate(0deg)',
                                  }}
                                />
                              )}
                            </Transition>
                          </IconButton>
                        </InputAdornment>
                      </>
                    ),
                  }}
                />
              )}
              filterOptions={x => x}
            />
          </Box>
        )}
      </Transition>
      <Collapse in={expanded} sx={{ pt: 0 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              value={fieldValue.data.addressLine1 ?? ''}
              onChange={event => handleChange('addressLine1', event.target.value)}
              size='small'
              fullWidth
              variant='outlined'
              label='Address Line 1'
              type='text'
              InputLabelProps={{
                shrink: fieldValue.data.addressLine1 !== null,
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              value={fieldValue.data.addressLine2 ?? ''}
              onChange={event => handleChange('addressLine2', event.target.value)}
              size='small'
              fullWidth
              variant='outlined'
              label='Address Line 2'
              type='text'
              InputLabelProps={{
                shrink: fieldValue.data.addressLine2 !== null,
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              value={fieldValue.data.aptSuite ?? ''}
              onChange={event => handleChange('aptSuite', event.target.value)}
              size='small'
              fullWidth
              variant='outlined'
              label='Apartment #'
              type='text'
              InputLabelProps={{
                shrink: fieldValue.data.aptSuite !== null,
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              value={fieldValue.data.floor ?? ''}
              onChange={event => handleChange('floor', event.target.value)}
              size='small'
              fullWidth
              variant='outlined'
              label='Floor #'
              type='text'
              InputLabelProps={{
                shrink: fieldValue.data.floor !== null,
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              value={fieldValue.data.cityTown ?? ''}
              onChange={event => handleChange('cityTown', event.target.value)}
              size='small'
              fullWidth
              variant='outlined'
              label='City/Town'
              type='text'
              InputLabelProps={{
                shrink: fieldValue.data.cityTown !== null,
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Autocomplete
              options={stateOptions}
              value={fieldValue.data.state}
              onChange={(event, data) => handleChange('state', data)}
              size='small'
              isOptionEqualToValue={(option, value) =>
                option.optionSeq.toUpperCase() === value.optionSeq.toUpperCase()
              }
              fullWidth
              getOptionLabel={option => option.optionName}
              renderInput={params => <TextField {...params} label='State' />}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              value={fieldValue.data.zipCode ?? ''}
              onChange={event => handleChange('zipCode', event.target.value)}
              size='small'
              fullWidth
              variant='outlined'
              label='Zip Code'
              type='text'
              InputLabelProps={{
                shrink: fieldValue.data.zipCode !== null,
              }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Autocomplete
              options={countryOptions}
              value={fieldValue.data.country}
              onChange={(event, data) => handleChange('country', data)}
              size='small'
              isOptionEqualToValue={(option, value) =>
                option.optionSeq.toUpperCase() === value.optionSeq.toUpperCase()
              }
              fullWidth
              getOptionLabel={option => option.optionName}
              renderInput={params => <TextField {...params} label='Country' />}
            />
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  );
}
